<template>
  <div class="flex justify-between items-center mb-8">
    <h2 class="text-2xl font-bold">Yardım</h2>
    <div class="flex flex-row gap-2">
    </div>
  </div>
  <div class="flex items-center space-x-4">
    <div class="flex flex-col gap-4 w-full">
      <div class="flex flex-col gap-4 p-4 bg-white rounded-md shadow">
        <h2 class="font-bold text-xl pb-4">Öğretmenlerin Davranış Şekilleri İçin Önerilen Seçenekler</h2>

        <div class="flex flex-col gap-1">
          <span class="font-bold">1. Sevecen (Friendly / Warm)</span>
          <span>Tanım: Velilere sıcak ve destekleyici bir iletişim sağlar.</span>
          <span>Kullanım Senaryosu: İlkokul seviyesinde, özellikle yeni velilerle veya hassas bir konuda
            konuşurken.</span>
          <span>Yapay Zeka Tonu: Cesaretlendirici, empatik ve samimi.</span>
          <span>Örnek Mesaj: "Ali gerçekten çok çalışkan bir çocuk! Matematikte büyük bir ilerleme kaydetti ve bu bizi
            çok mutlu ediyor."</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="font-bold">2. Disiplinli (Firm / Professional)</span>
          <span>Tanım: Resmi ve yapılandırılmış bir iletişim sunar. Net bilgi aktarımı sağlar.</span>
          <span>Kullanım Senaryosu: Ortaokul veya lise düzeyinde akademik performans veya disiplin ile ilgili
            konular.</span>
          <span>Yapay Zeka Tonu: Resmi, odaklı ve doğrudan.</span>
          <span>Örnek Mesaj: "Ali'nin matematikte daha düzenli çalışmaya ihtiyacı var. Ödevlerini zamanında tamamlaması,
            başarıyı artıracaktır."</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="font-bold">3. Destekleyici (Supportive / Encouraging)</span>
          <span>Tanım: Velilere ve öğrencilere motive edici ve destekleyici bir dil kullanır.</span>
          <span>Kullanım Senaryosu: Akademik veya davranışsal sorunların çözümünde.</span>
          <span>Yapay Zeka Tonu: Yapıcı, pozitif ve çözüm odaklı.</span>
          <span>Örnek Mesaj: "Ali bazen zorlanıyor gibi görünüyor, ancak onun potansiyeli çok yüksek. Belirli alanlarda
            birlikte çalışarak gelişimini hızlandırabiliriz."</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="font-bold">4. Bilgilendirici (Informative / Analytical)</span>
          <span>Tanım: Verilere ve analizlere dayalı detaylı bilgi sunar.</span>
          <span>Kullanım Senaryosu: Akademik raporlar veya performans analizi paylaşımı.</span>
          <span>Yapay Zeka Tonu: Veri odaklı, mantıklı ve tarafsız.</span>
          <span>Örnek Mesaj: "Ali'nin son üç sınav ortalaması %85. Bu, sınıf ortalamasının %10 üzerinde. Ancak, daha
            fazla ilerleme kaydedebilmesi için düzenli tekrar yapması faydalı olacaktır."</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="font-bold">5. Mizahi (Humorous / Playful)</span>
          <span>Tanım: Rahat bir iletişim tarzı benimser, gerektiğinde esprili bir dil kullanır.</span>
          <span>Kullanım Senaryosu: Rahatlatıcı bir ortam oluşturmak için, özellikle velilerle iyi bir ilişki kurmak
            isteniyorsa.</span>
          <span>Yapay Zeka Tonu: Eğlenceli, samimi ve biraz mizahi.</span>
          <span>Örnek Mesaj: "Ali'nin matematik sevgisi bazen kayboluyor gibi görünüyor ama endişelenmeyin, onu tekrar
            bulması için yanındayız!"</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="font-bold">6. Empatik (Empathetic / Compassionate)</span>
          <span>Tanım: Velilerin veya öğrencilerin duygusal durumuna duyarlı bir şekilde yanıt verir.</span>
          <span>Kullanım Senaryosu: Hassas konular (örneğin, düşük notlar, davranış sorunları) konuşulurken.</span>
          <span>Yapay Zeka Tonu: Anlayışlı, nazik ve samimi.</span>
          <span>Örnek Mesaj: "Bu dönemde biraz zorlanıyor olabilir, bu çok normal. Onunla birlikte çalışarak
            motivasyonunu artırabiliriz."</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="font-bold">7. Liderlik Odaklı (Leadership / Guiding)</span>
          <span>Tanım: Velilere net bir yol haritası sunar ve hedefe yönlendirir.</span>
          <span>Kullanım Senaryosu: Gelecek planlaması veya eğitim hedefleri belirlenirken.</span>
          <span>Yapay Zeka Tonu: İlham verici, kararlı ve yol gösterici.</span>
          <span>Örnek Mesaj: "Ali, hedeflerine ulaşmak için doğru yolda. Gelecek hafta daha detaylı bir çalışma planı
            yapabiliriz."</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="font-bold">8. Eleştirel ve Gelişim Odaklı (Constructive / Critical)</span>
          <span>Tanım: Dürüst ve yapıcı geri bildirimlerle velilerin gelişim alanlarını görmesine yardımcı olur.</span>
          <span>Kullanım Senaryosu: Performans sorunları veya hedeflere ulaşmak için eksik alanlar konuşulurken.</span>
          <span>Yapay Zeka Tonu: Doğrudan, açık ve yapıcı.</span>
          <span>Örnek Mesaj: "Ali'nin şu anki çabası yeterli görünmüyor. Ödevlerini düzenli yapması ve derste daha aktif
            olması çok önemli."</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="font-bold">9. Nötr (Neutral / Balanced)</span>
          <span>Tanım: Objektif ve dengeli bir iletişim sağlar.</span>
          <span>Kullanım Senaryosu: Hassas olmayan genel durum raporları veya bilgilendirme.</span>
          <span>Yapay Zeka Tonu: Tarafsız, net ve sakin.</span>
          <span>Örnek Mesaj: "Ali'nin matematikteki performansı bu dönemde %85 civarındaydı. Daha fazla pratik yapması
            faydalı olabilir."</span>
        </div>


      </div>
     

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { StudentAiDataModel, StudentDataModel, ClassRoom } from '@/models/models';
import { toast } from 'vue3-toastify';

export default defineComponent({
  name: 'Help',
  components: {},
  setup() {
    const Loading = ref<boolean>(false);
    const Student = ref<StudentDataModel>();
    const ClassRoom = ref<ClassRoom>();
    const StudentAiData = ref<StudentAiDataModel[]>([]);
    const route = useRoute();
    const message = ref<string>('');
    const email = ref<string>('emre@emre.com');

    return {
      Loading,
      Student,
      ClassRoom,
      StudentAiData,
      message,
      email
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
