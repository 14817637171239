<template>
    <div v-if="isOpen" class="fixed inset-0 bg-black bg-opacity-50 z-40" @click="handleOutsideClick">
      <!-- Sağdan kayan panel -->
      <div class="fixed right-0 top-0 h-full w-80 bg-white shadow-lg z-50 rounded-tl-3xl rounded-bl-3xl transform transition-transform duration-700"
           :class="isOpen ? 'translate-x-0' : 'translate-x-full'" @click.stop>
        <div class="p-4">
            <div class="card-actions justify-end">
      <button @click="closeSheet" class="btn-square btn-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
        
          <slot></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
  
  export default defineComponent({
    name: 'Sheet',
    setup(_, { expose }) {
      const isOpen = ref(false);
  
      const openSheet = () => {
        isOpen.value = true;
      };
  
      const closeSheet = () => {
        isOpen.value = false;
      };
  
      const handleOutsideClick = () => {
      closeSheet();
    };

      expose({ openSheet, closeSheet });
  
      return {
        isOpen,
        openSheet,
        closeSheet,
        handleOutsideClick
      };
    },
  });
  </script>
  
  <style scoped>
 .transition-transform {
  transition: transform 0.7s cubic-bezier(0.4, 0.0, 0.2, 1);
}
  </style>
  