<template>
  <div class="flex justify-between items-center mb-8">
    <h2 class="text-2xl font-bold">Ayarlar</h2>
    <div class="flex flex-row gap-2">
    </div>
  </div>
  <div v-if="!Loading" class="flex items-center space-x-4">
    <div class="flex flex-col gap-4 h-screen w-full">
      <div class=" p-4 bg-white rounded-md shadow">
        <div class="pb-4">
          <h2 class="text-lg font-bold pb-4">Şifre Değiştir</h2>


          <label class="block text-sm font-medium text-gray-700">Mevcut Şifreniz</label>
          <input v-model="passwords.initialPassword" :type="allPasswordsVisible ? 'text' : 'password'"
            placeholder="Mevcut Şifreniz"
            class="block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 focus:outline-none focus:bg-white focus:border-gray-500" />
          <label class="block text-sm font-medium text-gray-700">Yeni Şifreniz</label>
          <input rows="4" v-model="passwords.newPassword" :type="allPasswordsVisible ? 'text' : 'password'"
          placeholder="Yeni Şifreniz" 
          class="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          <label class="block text-sm font-medium text-gray-700">Yeni Şifreniz (Tekrar)</label>
          <input rows="4" v-model="passwords.newPasswordAgain" :type="allPasswordsVisible ? 'text' : 'password'"
          placeholder="Mevcut Şifreniz (Tekrar)" class="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />


          <div class="flex justify-between">

            <div class="mb-4 flex items-center">
              <input id="show-passwords" type="checkbox" v-model="allPasswordsVisible" class="mr-2" />
              <label for="show-passwords" class="text-gray-700">Şifreleri göster</label>
            </div>
            <button @click="updateUserPassword"
              class="rounded-md bg-indigo-600 px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Güncelle
            </button>
          </div>
        </div>
      </div>

      <div class="p-4 bg-white rounded-md shadow">
        <div class="pb-4">
          <h2 class="text-lg font-bold pb-4">ScoolMeet.Ai Veli Görüşme Adresi</h2>
          <a href="https://schoolmeet.ai/meet/x-okullari" target="_blank" class="text-indigo-600">{{ School.slug }}</a>
          <button @click="copyToClipboard" class="px-6 py-2.5 ml-8 ">
            <svg class="fill-current h-4 w-4 text-gray-600" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
              <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z">
              </path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getSchool } from '@/services/schoolService';
import { School } from '@/models/models';
import { updatePassword } from '@/services/authService';
import { toast } from 'vue3-toastify';

export default defineComponent({
  name: 'Settings',
  components: {

  },
  setup() {
    const Loading = ref<boolean>(true);
    const School = ref<School>({
      schoolId: 0,
      name: '',
      slug: '',
      description: '',
      openAiApiKey: '',
      createdAt: new Date(),
    });
    const route = useRoute();
    const message = ref<string>('');

    const passwords = ref({
      initialPassword: '',
      newPassword: '',
      newPasswordAgain: ''
    });

    const allPasswordsVisible = ref(false);


    onMounted(() => {
      fetchViewData()
    });

    const fetchViewData = async () => {

      const SchoolData = await getSchool();
      console.log(SchoolData.data);
      School.value = SchoolData.data.data;
      Loading.value = false;
    };


    const updateUserPassword = async () => {
      if (passwords.value.newPassword !== passwords.value.newPasswordAgain) {
        toast.error('Yeni şifreler eşleşmiyor!');
        return;
      }

      try {
        const requestData = {
          userId: JSON.parse(localStorage.getItem('user') || '{}').userId,
          initialPassword: passwords.value.initialPassword,
          newPassword: passwords.value.newPassword,
        };

        const response = await updatePassword(requestData);
        if (response.data.success) {
          toast.success(response.data.message);
          passwords.value.initialPassword = '';
          passwords.value.newPassword = '';
          passwords.value.newPasswordAgain = '';
        }
      } catch (error) {
        toast.error('Şifre güncellenirken bir hata oluştu.');
      }
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(School.value.slug);
      toast.info('Bağlantı kopyalandı.');
    };

    return {
      Loading,
      School,
      message,
      passwords,
      copyToClipboard,
      updateUserPassword,
      allPasswordsVisible,
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
