import { computed } from 'vue';
import { ClassRoom } from '@/models/models';
import apiClient from '@/axios';

const schoolId = computed(() => {
    const schoolData = JSON.parse(localStorage.getItem('school') || '{}');
    return schoolData?.value?.schoolId || 0;
  });
const userId = computed(() => JSON.parse(localStorage.getItem('user') || '{}')).value.userId;


export const getDashboardData = () => apiClient.get(`/dashboard`);
