import { computed } from 'vue';
import apiClient from '@/axios';
import { Teacher } from '@/models/models';

const schoolId = computed(() => {
    const schoolData = JSON.parse(localStorage.getItem('school') || '{}');
    return schoolData?.schoolId || 0;
  });

const userId = computed(() => JSON.parse(localStorage.getItem('user') || '{}')).value.userId;

export const getTeachers = () => apiClient.get(`/school/${schoolId.value}/users/`);
export const createAssistant = () => apiClient.post(`/user/create/assistant/`);
export const getTeacherById = (userId: number) => apiClient.get(`/user/${userId}`);
export const createTeacher = (data: Partial<Teacher>) => apiClient.post("/user/create/", data);
export const searchTeacher = (searchTerm: string) => apiClient.get(`/school/search/user?searchTerm=${searchTerm}`);
export const updateTeacher = (id: number, data: Partial<Teacher>) => apiClient.put(`/user/${id}`, data);

export const updateAssistant = (id: number, data: Partial<Teacher>) => apiClient.put(`/assistant/${id}`, data);

