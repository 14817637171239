<template>
  <div class="p-4">
    Chat Window
    <div v-for="(message, index) in messages" :key="index" :class="['message', message.sender]">
      <!-- User Message -->
      <div v-if="message.sender === 'user'" class="flex justify-end">
        <div class="bg-green-200 p-3 rounded-l-lg rounded-br-lg max-w-lg shadow-sm mb-2">
          <p class="">{{ message.text }}</p>
        </div>
      </div>
      <!-- Bot Message -->
      <div v-else class="flex justify-start">
        <div class="bg-slate-50 p-3 rounded-r-lg rounded-bl-lg max-w-lg shadow-sm mb-2">
          <p class="">{{ message.text }}</p>
        </div>
      </div>

    </div>
    <div v-if="isWaitingResponse" class="flex justify-start py-8 p-3">
      <span class="relative flex h-3 w-9">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-3 w-9 bg-indigo-500"></span>
      </span>
    </div>
    <div
      class="bg-gray-100 p-4 border-t border-gray-200 flex items-center space-x-4 fixed bottom-0 w-full max-w-7xl mx-auto">
      <input v-model="message" placeholder="Mesajınız..."
        class="flex-grow p-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
        @keyup.enter="sendMessage" />
      <button @click="sendMessage" class="bg-gray-900 text-white px-6 py-3 rounded-full shadow-md hover:bg-blue-600">
        Gönder
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onMounted } from 'vue';
import lottie from 'lottie-web';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';
interface Message {
  text: string;
  sender: string;
}

export default defineComponent({
  name: 'AssistantView',
  components: {},
  setup() {
    const message: Ref<string> = ref('');
    const Streamresponse = ref('');
    const messages: Ref<Message[]> = ref([]);
    const typingMessageIndex: Ref<number | null> = ref(null);
    const route = useRoute();
    const isWaitingResponse: Ref<boolean> = ref(false);
    const typingAnimation = ref<HTMLElement | null>(null);

    const lottieAnimations = {
      typing: null as any,
    };

    onMounted(() => {
      initLottieAnimations();
    });
    const initLottieAnimations = () => {
      console.log('2');
      if (typingAnimation.value) {
        console.log('Lottie Animations initialized');
        lottieAnimations.typing = lottie.loadAnimation({
          container: typingAnimation.value,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: '../animation.json',
        });
      }
    };

    const startStream = async () => {
      const eventSource = new EventSource(`http://localhost:5109/api/chat/${route.query.crid}/${route.query.sid}?message=${encodeURIComponent(message.value)}`);
      eventSource.onmessage = (event) => {
        Streamresponse.value += event.data + "\n"; // Gelen mesajları birleştir
        handleIncomingMessage(event.data);
      };
      message.value = "";
      eventSource.onerror = () => {
        eventSource.close();
        console.error("Streaming bağlantısı kesildi.");
        typingMessageIndex.value = null;
      };
    };

    const startStream2 = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/chat/${route.query.crid}/${route.query.sid}?message=${encodeURIComponent(message.value)}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Token'ı Header'da gönder
          }
        });


        if (response.status === 500) {
          toast.error('Server Error');
          isWaitingResponse.value = false;
          return;
        }

        if (!response.body) {
          console.error("No response body");
          return;
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        let done = false;
        isWaitingResponse.value = false;
        while (!done) {
          const { value, done: readerDone } = await reader.read(); // Parça parça veri oku
          done = readerDone;

          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            //console.log(chunk);
            //response.value += chunk; // Gelen mesajları birleştir
            scrollToBottom();
            handleIncomingMessage(chunk); // Mesajı işleyin
          }
        }
        typingMessageIndex.value = null;
      } catch (error) {

        console.error("Streaming bağlantısı kesildi:", error);
      }
    };

    const handleIncomingMessage = (text: string) => {
      if (typingMessageIndex.value === null) {
        messages.value.push({ text: text, sender: 'bot' });
        typingMessageIndex.value = messages.value.length - 1;
      } else {
        messages.value[typingMessageIndex.value].text += text;
      }
    };

    const sendMessage = async () => {
      if (message.value.trim() === '') return;
      messages.value.push({ text: message.value, sender: 'user' });
      scrollToBottom();
      isWaitingResponse.value = true;
      startStream2();
    };

    const scrollToBottom = () => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    };

    return {
      startStream,
      sendMessage,
      messages,
      message,
      isWaitingResponse,
      typingAnimation
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
