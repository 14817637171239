<template>
    <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold">{{ dialogTitle }}</h2>
                <button @click="$emit('close')" class="text-red-500 text-sm">
                    Kapat
                </button>
            </div>
            <form @submit.prevent="handleSubmit">
                <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-700">Sınıf Adı</label>
                    <input v-model="localClassRoom.name" id="name" type="text" class="input input-bordered w-full"
                        placeholder="Sınıf adı giriniz" required />
                </div>
                <div class="mb-4">
                    <label for="description" class="block text-sm font-medium text-gray-700">Açıklama</label>
                    <textarea v-model="localClassRoom.description" id="description"
                        class="textarea textarea-bordered w-full" placeholder="Açıklama giriniz"></textarea>
                </div>
                <div class="mb-4">
                    <label for="description" class="text-sm font-medium text-gray-700">Durum</label>
                   <input v-model="localClassRoom.isActive" id="isActive" type="checkbox" class="ml-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                </div>
                <div class="flex justify-end">
                 <button type="submit"
                        class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
                        {{ localClassRoom.classRoomId ? 'Güncelle' : 'Kaydet' }}
                    </button>
                </div>
            </form>
            <div class="flex flex-col gap-2 pt-4 text-sm">
                    <hr>
                    <span>Kayıt etmek için <kbd class="kbd kbd-xs">Ctrl + S</kbd></span>
                    <span>İptal etmek için <kbd class="kbd kbd-xs">Esc</kbd></span>
                </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch, onMounted, onUnmounted } from 'vue';
import { ClassRoom } from '@/models/models';

export default defineComponent({
    name: 'ClassRoomDialog',
    props: {
        dialogTitle: {
            type: String,
            required: true,
        },
        classRoom: {
            type: Object as PropType<ClassRoom | null>,
            default: null,
        },
    },
    emits: ['save', 'close'],
    setup(props, { emit }) {
        const localClassRoom = ref<ClassRoom>({
            classRoomId: 0,
            schoolId: 0,
            teacherId: 0,
            name: '',
            description: '',
            CreatedAt: new Date(),
            isActive: false,
        });

        // Props'tan gelen veriyi izleyerek localClassRoom'a kopyalar.
        watch(
            () => props.classRoom,
            (newValue) => {
                if (newValue) {
                    localClassRoom.value = { ...newValue };
                } else {
                    localClassRoom.value = {
                        classRoomId: 0,
                        schoolId: 0,
                        teacherId: 0,
                        name: '',
                        description: '',
                        CreatedAt: new Date(),
                        isActive: false,
                    };
                }
            },
            { immediate: true }
        );

        const handleSubmit = () => {
            emit('save', localClassRoom.value);
        };

        const handleKeydown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                emit('close'); // Escape ile dialog kapatma
            } else if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
                event.preventDefault(); // Tarayıcının varsayılan "kaydet" işlevini engeller
                handleSubmit(); // Ctrl + Enter ile kaydetme işlemi
            }
        };

        onMounted(() => {
            window.addEventListener('keydown', handleKeydown);
        });

        onUnmounted(() => {
            window.removeEventListener('keydown', handleKeydown);
        });


        return {
            localClassRoom,
            handleSubmit,
        };
    },
});
</script>

<style scoped>
/* İsteğe bağlı ek stiller */
.input-bordered {
    @apply border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500;
}

.textarea-bordered {
    @apply border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500;
}
</style>