import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "relative my-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "relative inline-flex items-center justify-center w-16 h-16 text-xl text-white bg-green-300 rounded-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.photoUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _ctx.photoUrl,
              alt: "Avatar",
              class: "relative rounded-full w-16 h-16"
            }, null, 8, _hoisted_3)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.initials), 1))
    ])
  ]))
}