<template>
  <div class="flex justify-between items-center mb-8">
    <h2 class="text-2xl font-bold">Öğrenci Detayı</h2>
    <div class="flex flex-row gap-2">
    </div>
  </div>
  <div v-if="!Loading" class="flex items-center space-x-4">
    <div class="flex flex-row gap-4 h-full w-full">
      <div class="w-1/4 p-4 bg-white rounded-md shadow">
        <div class="pb-4">
          <router-link :to="`/classRoom/${ClassRoom?.classRoomId}`"
            class="text-indigo-600 font-semibold flex items-center">
            <svg class="text-indigo-500 fill-current h-4 w-4 mr-2" viewBox="0 0 320 512"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z">
              </path>
            </svg>
            {{ ClassRoom?.name }}
          </router-link>
        </div>
        <div class="flex flex-row items-center gap-4">

          <Avatar :photoUrl="Student.photoUrl" :name="Student.name" :surname="Student.surname" />
          <h2 class="text-2xl font-bold mb-4">{{ Student.name }} {{ Student.middleName }} {{ Student.surname }}</h2>
        </div>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">Okul Numarası</h3>
          <p>{{ Student.schoolNumber }}</p>
        </div>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">Cinsiyet / Doğum Tarihi</h3>
          <p>Erkek / 2008</p>
        </div>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">Açıklama</h3>
          <p>{{ Student.description }}</p>
        </div>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">Kayıt Tarihi</h3>
          <p>{{ Student.createdAt }}</p>
        </div>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">Veli Bilgileri</h3>
          <div class="mb-2 p-3 text-sm border border-indigo-200 rounded-md bg-indigo-100">
            <div class="flex flex-col">
              <span class="font-bold">{{ Student.parent1FullName }}</span>
              <span class="block ">
                {{ Student.parent1PhoneNumber }} / {{ Student.parent1Email }}
              </span>
            </div>
          </div>
          <div class="p-3 text-sm border border-indigo-200 rounded-md bg-indigo-100">
            <div class="flex flex-col">
              <span class="font-bold">{{ Student.parent2FullName }}</span>
              <span class="block ">
                {{ Student.parent2PhoneNumber }} / {{ Student.parent2Email }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-3/4 p-4 bg-white rounded-md shadow">

        <div v-if="StudentAiData.length == 0"
          class="mt-2 mb-4 relative flex w-full p-3 text-sm border border-orange-200 text-slate-600 rounded-md bg-orange-50">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
            class="h-5 w-5 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z">
            </path>
          </svg>
          <div class="flex flex-col gap-2">
            <span class="font-bold">Öğrenciniz için henüz {{ ClassRoom?.name }} sınıfı için yapay zeka eğitim verisi
              girilmemiş.</span>
          </div>
        </div>

        <div class="pb-4">
          <h2 class="text-lg font-bold pb-4">Yapay Zeka Eğitim Verisi</h2>
          <textarea rows="4" v-model="message" placeholder="Veri..."
            class="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"></textarea>
          <div class="flex justify-end">
            <button @click="AddStudentAiData"
              class="rounded-md bg-indigo-600 px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Ekle
            </button>
          </div>
        </div>
        <div class="flex flex-col w-full overflow-scroll text-gray-700 bg-white shadow rounded-sm bg-clip-border">
          <table class="w-full text-left table-auto min-w-max text-slate-800">
            <thead>
              <tr class="text-slate-500 border-b border-slate-300 bg-slate-50">
                <th class="p-4">
                  <p class="text-sm leading-none font-normal">
                    Veri
                  </p>
                </th>
                <th class="p-4 whitespace-nowrap">
                  <p class="text-sm leading-none font-normal">
                    Veri Giriş Tarihi
                  </p>
                </th>
                <th class="p-4">
                  <p></p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, classIndex) in StudentAiData" :key="classIndex" class="hover:bg-slate-50">
                <td class="p-4">
                  {{ item.dataText }}
                </td>
                <td class="p-4">
                  {{ item.createdAt }}
                </td>
                <td class="p-4 text-right">
                  <button @click="deleteData(item.studentDataId)" class="text-red-600 hover:text-red-800">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="size-5">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                  </button>
                </td>
              </tr>
              <tr>
                <td v-if="StudentAiData.length == 0" colspan="3" class="p-4 text-center h-32">
                  Bu öğrenciniz için henüz veri girilmemiş.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getStudent, getStudentAiData, addStudentAiData } from '@/services/studentService';
import { getClassRoomById } from '@/services/classRoomService';
import { StudentAiDataModel, StudentDataModel, ClassRoom } from '@/models/models';
import { toast } from 'vue3-toastify';
import Avatar from '@/components/ui/Avatar.vue';

export default defineComponent({
  name: 'ClassRoomStudentDetail',
  components: {
    Avatar
  },
  setup() {
    const Loading = ref<boolean>(true);
    const Student = ref<StudentDataModel>();
    const ClassRoom = ref<ClassRoom>();
    const StudentAiData = ref<StudentAiDataModel[]>([]);
    const route = useRoute();
    const message = ref<string>('');

    onMounted(() => {
      fetchViewData();
    });
    const fetchViewData = async () => {
      const response = await getStudent(Number(route.params.studentId));
      Student.value = response.data.data;

      const responseClassRoom = await getClassRoomById(Number(route.params.id));
      ClassRoom.value = responseClassRoom.data.data;

      const responseStudenAiData = await getStudentAiData(Number(route.params.id), Number(route.params.studentId));
      StudentAiData.value = responseStudenAiData.data.data;

      Loading.value = false;
    };

    const AddStudentAiData = async () => {
      try {
        const response = await addStudentAiData({
          classRoomId: Number(route.params.id),
          studentId: Number(route.params.studentId),
          dataText: message.value
        });
        if (response.data.success) {
          message.value = '';
          toast.success(response.data.message);
          fetchViewData();
        }
      } catch (error) {
        console.error('Error posting student data:', error);
      }
    };

    return {
      Loading,
      Student,
      ClassRoom,
      StudentAiData,
      AddStudentAiData,
      message
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
