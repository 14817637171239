<template>

  <router-view></router-view>

</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({


})
</script>

<style scoped>

#app {
  font-family: Roboto, Jost, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.light {
  background-color: white;
  color: black;
}

body.dark {
  background-color: #1a202c;
  /* Dark tema için daha koyu arka plan */
  color: white;
}

@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}
</style>
