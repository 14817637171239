import { computed } from 'vue';
import apiClient from '@/axios';
import { StudentDataModel,StudentAiDataModel } from '@/models/models';

const schoolId = computed(() => {
  const schoolData = JSON.parse(localStorage.getItem('school') || '{}');
  return schoolData?.value?.schoolId || 0;
});

const userId = computed(() => JSON.parse(localStorage.getItem('user') || '{}')).value.userId;

export const getStudent = (id: number) => apiClient.get(`/student/${id}`);

export const fetchStudents = () =>
  apiClient.get(`/student/school/${schoolId.value}`);

export const saveStudent = (student: Partial<StudentDataModel>) =>
  apiClient.post('/student', student);

export const updateStudent = (id: number, student: Partial<StudentDataModel>) =>
  apiClient.put(`/student/${id}`, student);

export const getStudentAiData = (classroomId: number, studentId: number) =>
  apiClient.get(`/StudentData/${classroomId}/${studentId}`);



// Student Ai Data Services

export const addStudentAiData = (studentAiData: Partial<StudentAiDataModel>) =>
  apiClient.post('/StudentData/', studentAiData);

