<template>
    <Sheet ref="sheetRef">
      <h4 class="font-bold my-4">{{ isEditing ? 'Update API Key' : 'Add API Key' }}</h4>
  
      <form @submit.prevent="isEditing ? updateUserApiKey() : addUserApiKey()">
        <label class="block mb-2 text-sm text-slate-600">
        Exchange Name
      </label>
        <select v-model="formData.exchangeId" class="select select-bordered w-full mb-2">
          <option disabled value="">Select Exchange</option>
          <option v-for="exchange in exchanges" :key="exchange.exchangeId" :value="exchange.exchangeId">
            {{ exchange.name }}
          </option>
        </select>
        <label class="block mb-2 text-sm text-slate-600">
        Api Key
      </label>
        <input v-model="formData.apiKey" placeholder="API Key" class="input input-bordered w-full mb-2" />
        <label class="block mb-2 text-sm text-slate-600">
        Secret Key
      </label>
        <input v-model="formData.secretKey" placeholder="Secret Key" class="input input-bordered w-full mb-2" />
        <label class="cursor-pointer flex items-center py-4">
          <input v-model="formData.isActive" type="checkbox" class="toggle toggle-primary mr-2" />
          <span>Is Active</span>
        </label>
        <button type="submit" class="btn btn-md inline-flex py-1 px-4 font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg w-full">{{ isEditing ? 'Update' : 'Add' }}</button>
      </form>
    </Sheet>
  
    <h2 class="text-2xl font-bold mb-4">API Key Management</h2>
    <div class="p-6 bg-white rounded-lg shadow-md">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-bold">API Keys</h2>
        <button @click="openAddForm" class="inline-flex h-9 py-1 px-4 items-center text-sm font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg">Add New</button>
      </div>
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white text-left">
          <thead>
            <tr class="bg-gray-100">
              <th class="py-3 px-4">Exchange</th>
              <th class="py-3 px-4">API Key</th>
              <th class="py-3 px-4">Secret Key</th>
              <th class="py-3 px-4">Is Active</th>
              <th class="py-3 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="key in userApiKeys" :key="key.userApiKeyId">
              <td class="py-3 px-4">{{ getExchangeName(key.exchangeId) }}</td>
              <td class="py-3 px-4">{{ key.apiKey }}</td>
              <td class="py-3 px-4">{{ key.secretKey }}</td>
              <td class="py-3 px-4">{{ key.isActive ? 'Yes' : 'No' }}</td>
              <td class="py-3 px-4">
                <button @click="editUserApiKey(key)" class="text-blue-500 pr-2">Edit</button>
                <button @click="deleteUserApiKey(key.userApiKeyId)" class="text-red-500">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import apiClient from '@/axios';
  import Sheet from '@/components/ui/Sheet.vue';
  import { UserApiKey } from '@/models/UserApiKey';
  import { Exchange } from '@/models/Exchange';
  
  export default defineComponent({
    name: 'UserApiKeyCrud',
    components: { Sheet },
    setup() {
      const isEditing = ref(false);
      const userApiKeys = ref<UserApiKey[]>([]);
      const exchanges = ref<Exchange[]>([]);
      const formData = ref<UserApiKey>({
        userApiKeyId: 0,
        userId: 0,
        exchangeId: 0,
        apiKey: '',
        secretKey: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        isActive: true,
      });
      const sheetRef = ref<InstanceType<typeof Sheet> | null>(null);
  
      const fetchExchanges = async () => {
        const response = await apiClient.get('/exchange');
        exchanges.value = response.data.data;
      };
  
      const fetchUserApiKeys = async () => {
        const response = await apiClient.get('/userapikey');
        userApiKeys.value = response.data.data;
        console.log(userApiKeys.value);
      };
  
      const getExchangeName = (exchangeId: number) => {
        const exchange = exchanges.value.find(e => e.exchangeId === exchangeId);
        return exchange ? exchange.name : 'Unknown';
      };
  
      const openSheet = () => {
        sheetRef.value?.openSheet();
      };
  
      const openAddForm = () => {
        isEditing.value = false;
        formData.value = {
          userApiKeyId: 0,
          userId: 0,
          exchangeId: 0,
          apiKey: '',
          secretKey: '',
          createdAt: new Date(),
          updatedAt: new Date(),
          isActive: true,
        };
        openSheet();
      };
  
      const addUserApiKey = async () => {
        await apiClient.post('/userapikey', formData.value);
        fetchUserApiKeys();
        sheetRef.value?.closeSheet();
      };
  
      const editUserApiKey = (key: UserApiKey) => {
        formData.value = { ...key };
        isEditing.value = true;
        openSheet();
      };
  
      const updateUserApiKey = async () => {
        await apiClient.put(`/userapikey/${formData.value.userApiKeyId}`, formData.value);
        fetchUserApiKeys();
        sheetRef.value?.closeSheet();
      };
  
      const deleteUserApiKey = async (id: number) => {
        await apiClient.delete(`/userapikey/${id}`);
        fetchUserApiKeys();
      };
  
      onMounted(() => {
        fetchExchanges();
        fetchUserApiKeys();
      });
  
      return {
        userApiKeys,
        exchanges,
        formData,
        isEditing,
        openAddForm,
        editUserApiKey,
        updateUserApiKey,
        addUserApiKey,
        deleteUserApiKey,
        getExchangeName,
        sheetRef,
      };
    },
  });
  </script>
  