<!-- src/layouts/AuthLayout.vue -->
<template>
    <div class="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-800">
      <div class="w-full">
        <router-view></router-view>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'AuthLayout',
  });
  </script>
  
  <style scoped>
  /* Auth layout için isteğe bağlı stiller */
  </style>
  