<template>
  <div class="font-bold text-xl pb-8">
    Dashboard
  </div>
      <BinanceOrderBook symbol="BTCUSD"/>

    <div class="stats stats-vertical flex lg:stats-horizontal shadow mb-4">
      <div class="stat">
        <div class="stat-title">Downloads</div>
        <div class="stat-value">31K</div>
        <div class="stat-desc">Jan 1st - Feb 1st</div>
      </div>

      <div class="stat">
        <div class="stat-title">New Users</div>
        <div class="stat-value">4,200</div>
        <div class="stat-desc">↗︎ 400 (22%)</div>
      </div>

      <div class="stat">
        <div class="stat-title">New Registers</div>
        <div class="stat-value">1,200</div>
        <div class="stat-desc">↘︎ 90 (14%)</div>
      </div>
    </div>

    <div class="stats stats-vertical block shadow">
      <table class="table table-xs">
        <!-- MonitorItem -->
        <thead>
          <tr>
            <th></th>
            <th>Order Book</th>
            <th>Target (Sell))</th>
            <th>Source (Buy)</th>
            <th>Estimated Result</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in monitorItems.data" :key="index">
            <th>{{ index + 1 }}</th>
            <td>{{ monitorItems.orderBook }}</td>
            <td>
              <div>
                <div class="stat-title font-bold">{{ item.target.platform }}</div>
                <div class="stat-title">Balance: {{ item.target.balance }} USD</div>
                <div class="stat-title">Price: {{ item.target.price }} USD ({{ item.target.type }})</div>
                <div class="stat-title">Quantity: {{ item.target.maxQuantity.toFixed(5) }}</div>
              </div>
            </td>
            <td>
              <div>
                <div class="stat-title font-bold">{{ item.source.platform }}</div>
                <div class="stat-title">Balance: {{ item.source.balance }} USD</div>
                <div class="stat-title">Price: {{ item.source.price }} USD ({{ item.source.type }})</div>
                <div class="stat-title">Quantity: {{ item.source.maxQuantity.toFixed(5) }}</div>
              </div>
            </td>
            <td>
              <div class="stat-title">Quantity: {{ findMinQuantity(item.source.maxQuantity,
                item.target.maxQuantity).toFixed(5) }}</div>
              <div class="stat-title">Net Profit: {{item.estimatedResult.netProfit.toFixed(2)}} USD </div>
              <div class="stat-title">Ratio: {{ item.estimatedResult.profitRatio }}</div>
              <progress v-if="item.estimatedResult.profitRatio > monitorItems.targetProfitRate" class="progress progress-success w-56" value="50" max="100"></progress>
          
            </td>
            <td>
              <button :disabled="item.estimatedResult.profitRatio < monitorItems.targetProfitRate" class="btn btn-accent btn-sm text-white">Start</button>
              <div class="stat-desc">Calculation Time: 15 seconds ago</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import BinanceOrderBook  from '@/components/crypto/orderBook.vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    BinanceOrderBook
  },
  setup() {

    interface DataItem {
      platform: string;
      balance: number;
      price: number;
      commission: number;
      type: 'sell' | 'buy';
      maxQuantity: number;
    }

    interface DataEntry {
      target: DataItem;
      source: DataItem;
      estimatedResult: EstimatedResult;
    }

    interface EstimatedResult {
      profitRatio: number;
      maxQuantity: number;
      netProfit:number;
    }

    interface MonitorItem {
      orderBook: string;
      targetProfitRate: number;
      data: DataEntry[];
    }

    const monitorItems = ref<MonitorItem>({
      orderBook: 'USDT:USD',
      targetProfitRate: 0.0001,
      data: [
        {
          target: {
            platform: 'Binance',
            balance: 100000,
            price: 10.06,
            commission: 0.0001,
            type: 'sell',
            maxQuantity: 0
          },
          source: {
            platform: 'Bitflex',
            balance: 50000,
            price: 10.02,
            commission: 0.0005,
            type: 'buy',
            maxQuantity: 0
          },
          estimatedResult:{
            netProfit:89,
            maxQuantity: 4990,
            profitRatio: 0.00298
          }
        },
        {
          target: {
            platform: 'Binance',
            balance: 100000,
            price: 10.06,
            commission: 0.0001,
            type: 'sell',
            maxQuantity: 0
          },
          source: {
            platform: 'Bitflex',
            balance: 50000,
            price: 10.02,
            commission: 0.0005,
            type: 'buy',
            maxQuantity: 0
          },
          estimatedResult:{
            netProfit:89,
            maxQuantity: 4990,
            profitRatio: 0.00009
          }
        },
        {
          target: {
            platform: 'Binance',
            balance: 100000,
            price: 10.06,
            commission: 0.0001,
            type: 'sell',
            maxQuantity: 0
          },
          source: {
            platform: 'Bitflex',
            balance: 50000,
            price: 10.02,
            commission: 0.0005,
            type: 'buy',
            maxQuantity: 0
          },
          estimatedResult:{
            netProfit:89,
            maxQuantity: 4990,
            profitRatio: 0.00298
          }
        },
        {
          target: {
            platform: 'Binance',
            balance: 100000,
            price: 10.06,
            commission: 0.0001,
            type: 'sell',
            maxQuantity: 0
          },
          source: {
            platform: 'Bitflex',
            balance: 50000,
            price: 10.02,
            commission: 0.0005,
            type: 'buy',
            maxQuantity: 0
          },
          estimatedResult:{
            netProfit:89,
            maxQuantity: 4990,
            profitRatio: 0.00298
          }
        }
      ]

    });

    const calculateMaxQuantity = () => {
      monitorItems.value.data.forEach((entry) => {
        entry.target.maxQuantity = entry.target.balance / entry.target.price;
        entry.source.maxQuantity = entry.source.balance / entry.source.price;
      });
    };


    const findMinQuantity = (a: number, b: number): number => {
      return a < b ? a : b;
    };

    onMounted(() => {
      calculateMaxQuantity();
    });

    return {
      monitorItems,
      findMinQuantity
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
