<template>
    <Sheet ref="sheetRef">
      <h4 class="font-bold my-4">{{ isEditing ? 'Update Balance' : 'Add Balance' }}</h4>
  
      <form @submit.prevent="isEditing ? updateBalance() : addBalance()">
        <label class="block mb-2 text-sm text-slate-600">
        Exchange Name
      </label>
        <select v-model="formData.exchangeId" class="select select-bordered w-full mb-2">
          <option disabled value="">Select Exchange</option>
          <option v-for="exchange in exchanges" :key="exchange.exchangeId" :value="exchange.exchangeId">
            {{ exchange.name }}
          </option>
        </select>
        <label class="block mb-2 text-sm text-slate-600">
        Asset
      </label>
        <input v-model="formData.asset" placeholder="Asset" class="input input-bordered w-full mb-2" />
        <input v-model.number="formData.available" type="number" placeholder="Available Balance" class="input input-bordered w-full mb-2" />
        <input v-model.number="formData.locked" type="number" placeholder="Locked Balance" class="input input-bordered w-full mb-2" />
        <button type="submit" class="btn btn-md inline-flex py-1 px-4 font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg w-full">{{ isEditing ? 'Update' : 'Add' }}</button>
    </form>
    </Sheet>
    <h2 class="text-2xl font-bold mb-4">Balace Management</h2>
    <div class="p-6 bg-white rounded-lg shadow-md">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-bold">Exchange Balances</h2>
        <button @click="openAddForm" class="inline-flex h-9 py-1 px-4 items-center text-sm font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg">Add New</button>
    </div>
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white text-left">
          <thead> 
            <tr class="bg-gray-100">
              <th class="py-3 px-4">Exchange</th>
              <th class="py-3 px-4">Asset</th>
              <th class="py-3 px-4">Available</th>
              <th class="py-3 px-4">Locked</th>
              <th class="py-3 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="balance in userBalances" :key="balance.balanceId">
              <td class="py-3 px-4">{{ getExchangeName(balance.exchangeId) }}</td>
              <td class="py-3 px-4">{{ balance.asset }}</td>
              <td class="py-3 px-4">{{ balance.available }}</td>
              <td class="py-3 px-4">{{ balance.locked }}</td>
              <td class="py-3 px-4">
                <button @click="editBalance(balance)" class="text-blue-500">Update</button>
                <button @click="deleteBalance(balance.balanceId)" class="text-red-500">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import apiClient from '@/axios';
  import Sheet from '@/components/ui/Sheet.vue';
  import { UserBalance } from '@/models/UserBalance';
  import { Exchange } from '@/models/Exchange';
  
  export default defineComponent({
    name: 'UserBalanceList',
    components: { Sheet },
    setup() {
      const isEditing = ref(false);
      const userBalances = ref<UserBalance[]>([]);
      const exchanges = ref<Exchange[]>([]);
      const formData = ref<UserBalance>({
        balanceId: 0,
        userId: 0,
        exchangeId: 0,
        asset: '',
        available: 0,
        locked: 0,
        updatedAt: new Date(),
      });
      const sheetRef = ref<InstanceType<typeof Sheet> | null>(null);
  
      const fetchExchanges = async () => {
        const response = await apiClient.get('/exchange');
        exchanges.value = response.data.data;
      };
  
      const fetchUserBalances = async () => {
        const response = await apiClient.get('/userbalance');
        userBalances.value = response.data.data;
      };
  
      const getExchangeName = (exchangeId: number) => {
        const exchange = exchanges.value.find(e => e.exchangeId === exchangeId);
        return exchange ? exchange.name : 'Unknown';
      };
  
      const openSheet = () => {
        sheetRef.value?.openSheet();
      };
  
      const openAddForm = () => {
        isEditing.value = false;
        formData.value = {
          balanceId: 0,
          userId: 0,
          exchangeId: 0,
          asset: '',
          available: 0,
          locked: 0,
          updatedAt: new Date(),
        };
        openSheet();
      };
  
      const addBalance = async () => {
        await apiClient.post('/userbalance', formData.value);
        fetchUserBalances();
        sheetRef.value?.closeSheet();
      };
  
      const editBalance = (balance: UserBalance) => {
        formData.value = { ...balance };
        isEditing.value = true;
        openSheet();
      };
  
      const updateBalance = async () => {
        await apiClient.put(`/userbalance/${formData.value.balanceId}`, formData.value);
        fetchUserBalances();
        sheetRef.value?.closeSheet();
      };
  
      const deleteBalance = async (id: number) => {
        await apiClient.delete(`/userbalance/${id}`);
        fetchUserBalances();
      };
  
      onMounted(() => {
        fetchExchanges();
        fetchUserBalances();
      });
  
      return {
        userBalances,
        exchanges,
        formData,
        isEditing,
        openAddForm,
        editBalance,
        updateBalance,
        addBalance,
        deleteBalance,
        getExchangeName,
        sheetRef,
      };
    },
  });
  </script>
  