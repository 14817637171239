import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-theme"]
const _hoisted_2 = { class: "relative z-50" }
const _hoisted_3 = { class: "mx-auto lg:ml-80 dark:bg-black bg-slate-100" }
const _hoisted_4 = { class: "p-4" }
const _hoisted_5 = { class: "flex flex-col pb-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", { "data-theme": _ctx.theme }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Sidebar),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NavBar, {
          onToggleSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sidebarVisible = !_ctx.sidebarVisible))
        }),
        _createElementVNode("main", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_view)
          ])
        ])
      ])
    ])
  ], 8, _hoisted_1))
}