import { onMounted, onUnmounted } from 'vue';

interface Shortcut {
  key: string;
  ctrlKey?: boolean;
  action: () => void;
}

export const useKeyboardShortcuts = (shortcuts: Shortcut[]) => {
  const handleKeydown = (event: KeyboardEvent) => {
    shortcuts.forEach((shortcut) => {
      if (
        event.key === shortcut.key &&
        (!shortcut.ctrlKey || event.ctrlKey || event.metaKey)
      ) {
        event.preventDefault();
        shortcut.action();
      }
    });
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeydown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeydown);
  });
};
