<template>
  <Sheet ref="sheetRef">
    <h4 class="font-bold my-4">{{ isEditing ? 'Update Trading Bot' : 'Add Trading Bot' }}</h4>

    <form @submit.prevent="isEditing ? updateTradingBot() : addTradingBot()">
      <label class="block mb-2 text-sm text-slate-600">
        Source Exchange
      </label>

      <select v-model="formData.sourceExchangeId" class="select select-bordered w-full mb-2">
        <option disabled value="">Select Source Exchange</option>
        <option v-for="exchange in exchanges" :key="exchange.exchangeId" :value="exchange.exchangeId">
          {{ exchange.name }}
        </option>
      </select>
      <label class="block mb-2 text-sm text-slate-600">
        Target Exchange
      </label>
      <select v-model="formData.targetExchangeId" class="select select-bordered w-full mb-2">
        <option disabled value="">Select Target Exchange</option>
        <option v-for="exchange in exchanges" :key="exchange.exchangeId" :value="exchange.exchangeId">
          {{ exchange.name }}
        </option>
      </select>
      <label class="block mb-2 text-sm text-slate-600">
        Trading Amount (USD)
      </label>
      <input v-model.number="formData.tradingAmount" type="number" placeholder="Trading Amount"
        class="input input-bordered w-full mb-2" />

      <label class="block mb-2 text-sm text-slate-600">
        Margin (%)
      </label>
      <input v-model.number="formData.profitMargin" placeholder="Profit Margin" class="input input-bordered w-full mb-2" />
      <label class="cursor-pointer flex items-center py-4">
        <input v-model="formData.isActive" type="checkbox" class="toggle toggle-primary mr-2" />
        <span>Is Active</span>
      </label>
      <button type="submit"
        class="btn btn-md inline-flex py-1 px-4 font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg w-full">{{
          isEditing ? 'Update' : 'Add' }}</button>
    </form>
  </Sheet>
  <h2 class="text-2xl font-bold mb-4">Bot Management</h2>
  <div class="p-6 bg-white rounded-lg shadow-md">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Trading Bots</h2>
      <button @click="openAddForm"
        class="inline-flex h-9 py-1 px-4 items-center text-sm font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg">Add
        New</button>
    </div>
    <div class="overflow-x-auto">
      <table class="min-w-full bg-white">
        <thead>
          <tr class="bg-gray-100 text-left">
            <th @click="sortTable('sourceExchange')" class="cursor-pointer py-3 px-4">Source Exchange</th>
            <th @click="sortTable('targetExchange')" class="cursor-pointer py-3 px-4">Target Exchange</th>
            <th @click="sortTable('tradingAmount')" class="cursor-pointer py-3 px-4">Trading Amount</th>
            <th @click="sortTable('profitMargin')" class="cursor-pointer py-3 px-4">Profit Margin</th>
            <th class="cursor-pointer py-3 px-4">Last Checked At</th>
            <th class="py-3 px-4">Is Active</th>
            <th class="py-3 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bot in sortedBots" :key="bot.tradingBotId">
            <td class="py-3 px-4">{{ getExchangeName(bot.sourceExchangeId) }}</td>
            <td class="py-3 px-4">{{ getExchangeName(bot.targetExchangeId) }}</td>
            <td class="py-3 px-4">{{ bot.tradingAmount.toFixed(2) }} USD</td>
            <td class="py-3 px-4">%{{ bot.profitMargin }}</td>
            <td class="py-3 px-4">{{ $dayjs(bot.lastCheckedAt).utc().format('DD.MM.YYYY HH:mm:SSS') }}</td>
            <td class="py-3 px-4">
              <span
                :class="['px-2 py-1 rounded-full text-xs', bot.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800']">
                {{ bot.isActive ? 'Active' : 'Passive' }}
              </span>

            </td>
            <td class="py-3 px-4">
              <button @click="editTradingBot(bot)" class="text-blue-500 pr-2">Edit</button>
              <button @click="deleteTradingBot(bot.tradingBotId)" class="text-red-500">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import apiClient from '@/axios';
import Sheet from '@/components/ui/Sheet.vue';
import { TradingBot } from '@/models/TradingBot';
import { Exchange } from '@/models/Exchange';

export default defineComponent({
  name: 'TradingBotCrud',
  components: { Sheet },
  setup() {

    const isEditing = ref(false);
    const tradingBots = ref<TradingBot[]>([]);
    const exchanges = ref<Exchange[]>([]);
    const formData = ref<TradingBot>({
      tradingBotId: 0,
      sourceExchangeId: 0,
      targetExchangeId: 0,
      tradingAmount: 0,
      profitMargin: 0,
      isActive: false,
      createdAt: new Date(),
      lastCheckedAt: new Date(),
    });
    const sheetRef = ref<InstanceType<typeof Sheet> | null>(null);

    const openSheet = () => {
      sheetRef.value?.openSheet();
    };

    const openAddForm = () => {
      isEditing.value = false;
      formData.value = {
        tradingBotId: 0,
        sourceExchangeId: 0,
        targetExchangeId: 0,
        tradingAmount: 0,
        profitMargin: 0,
        isActive: false,
        createdAt: new Date(),
        lastCheckedAt: new Date(),
      };
      openSheet();
    };

    const fetchExchanges = async () => {
      const response = await apiClient.get('/exchange');
      exchanges.value = response.data.data;
    };


    const fetchTradingBots = async () => {
      const response = await apiClient.get('/bot');
      tradingBots.value = response.data.data;
    };

    const addTradingBot = async () => {
      await apiClient.post('/bot', formData.value);
      fetchTradingBots();
      sheetRef.value?.closeSheet();
    };

    const editTradingBot = (bot: TradingBot) => {
      formData.value = { ...bot };
      isEditing.value = true;
      openSheet();
    };

    const updateTradingBot = async () => {

      await apiClient.put(`/bot/${formData.value.tradingBotId}`, formData.value)
        .then((response: { data: any }) => {
          fetchTradingBots();
          sheetRef.value?.closeSheet();
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            //console.error('Validation Error:', error.response.data.message);
            alert(error.response.data.data);
          } else {
            console.error('An unexpected error occurred:', error);
          }
        });
    };

    const deleteTradingBot = async (id: number) => {
      await apiClient.delete(`/bot/${id}`);
      fetchTradingBots();
    };

    const sortKey = ref('');
    const sortOrder = ref('asc');

    const sortTable = (key: string) => {
      if (sortKey.value === key) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortKey.value = key;
        sortOrder.value = 'asc';
      }
    };

    const sortedBots = computed(() => {
      if (!sortKey.value) return tradingBots.value;

      return [...tradingBots.value].sort((a, b) => {
        const valueA = a[sortKey.value as keyof TradingBot] as string | number;
        const valueB = b[sortKey.value as keyof TradingBot] as string | number;

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return sortOrder.value === 'asc'
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        return sortOrder.value === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
      });
    });

    const getExchangeName = (exchangeId: number) => {
      const exchange = exchanges.value.find((e) => e.exchangeId === Number(exchangeId));
      return exchange ? exchange.name : 'Unknown';
    };

    onMounted(() => {
      fetchExchanges();
      fetchTradingBots();
    });

    return {
      tradingBots,
      formData,
      isEditing,
      openAddForm,
      editTradingBot,
      updateTradingBot,
      addTradingBot,
      deleteTradingBot,
      sortTable,
      sortedBots,
      sheetRef,
      exchanges,
      getExchangeName,
    };
  },
});
</script>
