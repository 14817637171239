<template>
  <nav class="flex justify-between items-center p-4 rounded-xl">
    <h1 class="text-xl font-bold"></h1>

    <!-- Tema Geçiş ve Sidebar Butonları -->
    <div class="flex items-center space-x-4">
      <!-- Tema Geçiş Butonu -->

      <div class="pl-2 text-lg font-bold" v-if="school">{{ school.name }}</div>

      <!-- Kullanıcı Menüsü (Açılır Menü) -->
      <div class="dropdown dropdown-end z-10">
        <button tabindex="0" class="btn btn-ghost btn-circle avatar">
          <div class="w-10 rounded-full">
            <img src="https://i.pravatar.cc/300" alt="User Avatar" />
          </div>
        </button>

        <ul class="dropdown-content menu p-2 shadow bg-base-100 dark:bg-gray-700 rounded-md w-64 gap-2">
          <div class="flex flex-col p-2" v-if="user">
            <span class="font-bold"> {{ user.fullName }}</span>
            <span class="text-gray-600 text-sm">{{ user.role === 'Admin' ? 'Yönetici' : 'Öğretmen' }}</span>
          </div>
          <li><router-link :to="`/teacher/${user.userId}`">Yapay Zeka Asistanım</router-link></li>
          <li><router-link to="/settings">Ayarlar</router-link></li>
          <li><router-link to="/help">Yardım</router-link></li>
          <li class=" border-t ">

            <button @click="logout" class="btn btn-sm text-white btn-error w-full mt-4">Logout</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, inject, Ref, computed } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Navbar',
  setup() {
    const theme = inject('theme') as Ref<string>;
    const toggleTheme = inject('toggleTheme') as () => void;
    const router = useRouter();
    const user = computed(() => JSON.parse(localStorage.getItem('user') || '{}'));
    const school = computed(() => JSON.parse(localStorage.getItem('school') || '{}'));

    const logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('school');
      localStorage.removeItem('isAuthenticated');
      router.push('/login');
    };

    return {
      school,
      user,
      theme,
      toggleTheme,
      logout,
    };
  },
});
</script>

<style scoped>
/* Navbar için ek stiller (isteğe bağlı) */
</style>
