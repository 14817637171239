import { computed } from 'vue';
import apiClient from '@/axios';
import { School } from '@/models/models';

const schoolId = computed(() => {
    const schoolData = JSON.parse(localStorage.getItem('school') || '{}');
    return schoolData?.schoolId || 0;
});

export const getSchool = () => apiClient.get(`/school/${schoolId.value}`);
export const updateSchool = (id: number, data: Partial<School>) => apiClient.put(`/school/${id}`, data);