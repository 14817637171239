<template>
  <Sheet ref="sheetRef">
    <h4 class="font-bold my-4">{{ isEditing ? 'Update Exchange' : 'Add Exchange' }}</h4>

    <form @submit.prevent="isEditing ? updateExchange() : addExchange()">

      <label class="block mb-2 text-sm text-slate-600">
        Exchange Name
      </label>

      <input v-model="formData.name" placeholder="Name" class="input input-bordered w-full mb-2" />

      <label class="block mb-2 text-sm text-slate-600">
        Api URL
      </label>

      <input v-model="formData.apiUrl" placeholder="API URL" class="input input-bordered w-full mb-2" />

      <label class="block mb-2 text-sm text-slate-600">
        Maker Fee
      </label>

      <div class="relative">
        <input v-model="formData.makerFee" placeholder="Maker Fee"
          class="input input-bordered w-full mb-2 pr-3 pl-10 py-2" />
        <span class="absolute left-1 top-1 p-2 rounded-md text-center bg-slate-100">%</span>
      </div>

      <label class="block mb-2 text-sm text-slate-600">
        Taker Fee
      </label>

      <div class="relative">
        <input v-model="formData.takerFee" placeholder="Taker Fee"
          class="input input-bordered w-full mb-2 pr-3 pl-10 py-2" />
        <span class="absolute left-1 top-1 p-2 rounded-md text-center bg-slate-100">%</span>
      </div>
 
      <button type="submit" class="btn btn-md inline-flex py-1 px-4 font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg w-full">{{ isEditing ? 'Update' : 'Add' }}</button>
    </form>
  </Sheet>

  <div>
    <h2 class="text-2xl font-bold mb-4">Exchange Management</h2>
    <div class="p-6 bg-white rounded-lg shadow-md">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-bold text-gray-800">Exchanges</h2>
        <button @click="openAddForm" class="inline-flex h-9 py-1 px-4 items-center text-sm font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg">Add New</button>
      </div>
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead>
            <tr class="bg-gray-100 text-left">
              <th class="py-3 px-4">Name</th>
              <th class="py-3 px-4">API URL</th>
              <th class="py-3 px-4">Maker Fee</th>
              <th class="py-3 px-4">Taker Fee</th>
              <th class="py-3 px-4">Actions</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr v-for="exchange in exchanges" :key="exchange.exchangeId">
              <td class="py-3 px-4">{{ exchange.name }}</td>
              <td class="py-3 px-4">{{ exchange.apiUrl }}</td>
              <td class="py-3 px-4">% {{ exchange.makerFee }}</td>
              <td class="py-3 px-4">% {{ exchange.takerFee }}</td>
              <td class="py-3 px-4">
                <button @click="editExchange(exchange)" class="text-blue-500 pr-2">Update</button>
                <button @click="deleteExchange(exchange.exchangeId)" class="text-red-500">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import apiClient from '@/axios';
import Sheet from '@/components/ui/Sheet.vue';
import { Exchange } from '@/models/Exchange';

export default defineComponent({
  name: 'ExchangeCrud',
  components: { Sheet },
  setup() {

    const isEditing = ref(false);
    const exchanges = ref<Exchange[]>([]);
    const formData = ref<Exchange>({
      exchangeId: 0,
      name: '',
      apiUrl: '',
      makerFee: 0,
      takerFee: 0,
      createdAt: new Date(),
    });
    const sheetRef = ref<InstanceType<typeof Sheet> | null>(null);

    const openSheet = () => {
      sheetRef.value?.openSheet();
    };

    const openAddForm = () => {
      isEditing.value = false;
      formData.value = {
        exchangeId: 0,
        name: '',
        apiUrl: '',
        makerFee: 0,
        takerFee: 0,
        createdAt: new Date(),
      };
      openSheet();
    };

    const fetchExchanges = async () => {
      const response = await apiClient.get('/exchange');
      console.log(response.data.data);
      exchanges.value = response.data.data;
    };

    const addExchange = async () => {
      console.log(formData.value);
      await apiClient.post('/exchange', formData.value);
      fetchExchanges();
      sheetRef.value?.closeSheet();
    };

    const editExchange = (exchange: Exchange) => {
      formData.value = { ...exchange };
      isEditing.value = true;
      openSheet();
    };

    const updateExchange = async () => {
      await apiClient.put(`/exchange/${formData.value.exchangeId}`, formData.value);
      fetchExchanges();
      sheetRef.value?.closeSheet();
    };

    const deleteExchange = async (id: number) => {
      await apiClient.delete(`/exchange/${id}`);
      fetchExchanges();
    };

    onMounted(fetchExchanges);

    return {
      exchanges,
      formData,
      isEditing,
      openAddForm,
      editExchange,
      updateExchange,
      addExchange,
      deleteExchange,
      sheetRef,
    };
  },
});
</script>
