import { createApp, ComponentPublicInstance, ref } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import './assets/tailwind.css';
import globalComponents from './plugins/globalComponents';
import dayjsPlugin from './plugins/dayjs';
import Sheet from '@/components/ui/Sheet.vue';

import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import './styles/toast.css';

const app = createApp(App);
const pinia = createPinia();

app.component('Sheet', Sheet);

// Sheet bileşenine referans ekleyin
type SheetInstance = ComponentPublicInstance<{ openSheet: () => void; closeSheet: () => void }>;

const sheetRef = ref<SheetInstance | null>(null);

app.config.globalProperties.$setSheetRef = (ref: any) => {
  sheetRef.value = ref;
};

app.config.globalProperties.$openSheet = () => {
  if (sheetRef.value) {
    sheetRef.value.openSheet();
  }
};

app.config.globalProperties.$closeSheet = () => {
  if (sheetRef.value) {
    sheetRef.value.closeSheet();
  }
};

app.use(Vue3Toastify, {
  autoClose: 3000, // Bildirimlerin otomatik olarak kapanma süresi (ms)
  position: 'top-right', // Bildirimlerin ekranda görünme konumu
} as ToastContainerOptions);


app.use(dayjsPlugin);
app.use(pinia);
app.use(router);
app.use(globalComponents);
app.mount('#app');
