<template>

  <!-- Header -->
  <div class="flex justify-between items-center mb-8">
    <h2 class="text-2xl font-bold">Okul Öğrenci Listesi</h2>
    <div class="flex flex-row gap-2">
      <a href="#" class="btn-secondary">Arşiv</a>
      <button @click="openDialog" class="btn-primary">Öğrenci Ekleyin</button>
    </div>
  </div>

  <!-- Student Table -->
  <div class="relative flex flex-col w-full overflow-scroll text-gray-700 bg-white shadow-md rounded-lg bg-clip-border">
    <table class="w-full text-left table-auto min-w-max text-slate-800">
      <thead>
        <tr class="text-slate-500 border-b border-slate-300 bg-slate-50">
          <th class="p-4 w-24"></th>
          <th class="p-4">Öğrenci</th>
          <th class="p-4">Okul Numarası</th>
          <th class="p-4">Cinsiyet</th>
          <th class="p-4">Doğum Yılı</th>
          <th class="p-4">Veli Bilgileri</th>
          <th class="p-4">Kayıt Tarihi</th>
          <th class="p-4"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="student in students" :key="student.studentId" class="hover:bg-slate-50">
          <td class="p-4 w-10">
            <Avatar :photoUrl="student.photoUrl" :name="student.name" :surname="student.surname" />
          </td>
          <td class="p-4">
            <p class="text-sm font-bold">
              {{ student.name }} {{ student.middleName }} {{ student.surname }}
            </p>
          </td>
          <td class="p-4">
            <p class="text-sm">{{ student.schoolNumber }}</p>
          </td>
          <td class="p-4">
            <p class="text-sm">{{ student.gender }}</p>
          </td>
          <td class="p-4">
            <p class="text-sm">{{ student.birthYear }}</p>
          </td>
          <td class="p-4">
            <div class="mb-2 p-3 text-sm border border-indigo-200 rounded-md bg-indigo-100">
              <div class="flex flex-col">
                <span class="font-bold">{{student.parent1FullName}}</span>
                <span class="block ">
                 {{student.parent1PhoneNumber}} / {{student.parent1Email}}
                </span>
              </div>
            </div>

            <div class="p-3 text-sm border border-indigo-200 rounded-md bg-indigo-100">
              <div class="flex flex-col">
                <span class="font-bold">{{ student.parent2FullName }}</span>
                <span class="block ">
                  {{ student.parent2PhoneNumber }} / {{ student.parent2Email }}
                </span>
              </div>
            </div>
          </td>
          <td class="p-4">
            <p class="text-sm">{{ student.createdAt }}</p>
          </td>
          <td class="p-4 text-right">
            <button @click="editStudent(student)" class="btn-primary">
              Güncelle
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Dialog -->
  <div v-if="showDialog" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-4xl  w-full">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">{{ dialogTitle }}</h2>
        <button @click="closeDialog" class="text-red-500 text-sm">
          Kapat
        </button>
      </div>
      <form @submit.prevent="handleSave">
        <div class="flex flex-col gap-4">
          <div class="grid grid-cols-4 grid-flow-row gap-4">
            <div>
              <label for="schoolNumber" class="block text-sm font-medium text-gray-700">Okul Numarası</label>
              <input v-model="currentStudent.schoolNumber" id="schoolNumber" type="text" class="input input-bordered w-full" placeholder="Okul numarası giriniz" required />
            </div>
            <div>
              <label for="name" class="block text-sm font-medium text-gray-700">Öğrenci Adı</label>
              <input v-model="currentStudent.name" id="name" type="text" class="input input-bordered w-full" placeholder="Öğrenci adı giriniz" required />
            </div>
            <div>
              <label for="middleName" class="block text-sm font-medium text-gray-700">Orta Adı</label>
              <input v-model="currentStudent.middleName" id="middleName" type="text" class="input input-bordered w-full" placeholder="Orta adı giriniz" />
            </div>
            <div>
              <label for="surname" class="block text-sm font-medium text-gray-700">Soyadı</label>
              <input v-model="currentStudent.surname" id="surname" type="text" class="input input-bordered w-full" placeholder="Soyadı giriniz" required />
            </div>
            <div>
              <label for="gender" class="block text-sm font-medium text-gray-700">Cinsiyet</label>
              <select v-model="currentStudent.gender" class="input input-bordered w-full">
                <option value="Erkek">Erkek</option>
                <option value="Kız">Kız</option>
              </select>
            </div>
            <div>
              <label for="birthyear" class="block text-sm font-medium text-gray-700">Doğum Yılı</label>
              <input v-model="currentStudent.birthYear" id="surname" type="text" class="input input-bordered w-full" placeholder="Doğum Yılı" required />
            </div>
          </div>
          <h2 class="text-lg font-bold pt-4">1. Veli Bilgileri</h2>
          <div class="flex flex-row gap-4">
            <div>
              <label for="name" class="block text-sm font-medium text-gray-700">Ad Soyad</label>
              <input v-model="currentStudent.parent1FullName" id="name" type="text" class="input input-bordered w-full" />
            </div>
            <div>
              <label for="surname" class="block text-sm font-medium text-gray-700">Telefon Numarası</label>
              <input v-model="currentStudent.parent1PhoneNumber" id="surname" type="text" class="input input-bordered w-full" />
            </div>
            <div>
              <label for="middleName" class="block text-sm font-medium text-gray-700">E-posta</label>
              <input v-model="currentStudent.parent1Email" id="middleName" type="text" class="input input-bordered w-full" />
            </div>
          </div>
          <h2 class="text-lg font-bold pt-4">2. Veli Bilgileri</h2>
          <div class="flex flex-row gap-4">
            <div>
              <label for="name" class="block text-sm font-medium text-gray-700">Ad Soyad</label>
              <input v-model="currentStudent.parent2FullName" id="name" type="text" class="input input-bordered w-full"/>
            </div>
            <div>
              <label for="surname" class="block text-sm font-medium text-gray-700">Telefon Numarası</label>
              <input v-model="currentStudent.parent2PhoneNumber" id="surname" type="text" class="input input-bordered w-full" />
            </div>
            <div>
              <label for="middleName" class="block text-sm font-medium text-gray-700">E-posta</label>
              <input v-model="currentStudent.parent2Email" id="middleName" type="text" class="input input-bordered w-full" />
            </div>
          </div>
        </div>
        <div>
          <div class="flex justify-end space-x-4">
            <button type="submit"
              class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
              {{ currentStudent.studentId ? 'Güncelle' : 'Kaydet' }}
            </button>
          </div>
        </div>
      </form>
      <div class="flex flex-col gap-2 pt-4 text-sm">
        <hr>
        <span>Kayıt etmek için <kbd class="kbd kbd-xs">Ctrl + S</kbd></span>
        <span>İptal etmek için <kbd class="kbd kbd-xs">Esc</kbd></span>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { fetchStudents, saveStudent, updateStudent } from '@/services/studentService';
import { StudentDataModel } from '@/models/models';
import Avatar from '@/components/ui/Avatar.vue';
import { useKeyboardShortcuts } from '@/utils/useKeyboardShortcuts';

export default defineComponent({
  name: 'SchoolStudentList',
  components: { Avatar },
  setup() {
    const route = useRoute();
    const students = ref<StudentDataModel[]>([]);
    const dialogTitle = ref('');
    const currentStudent = ref<StudentDataModel>({
      studentId: 0,
      schoolId: 0,
      schoolNumber: '',
      name: '',
      middleName: '',
      surname: '',
      photoUrl: '',
      description: '',
      createdAt: new Date(),
      parent1FullName: '',
      parent1PhoneNumber: '',
      parent1Email: '',
      parent2FullName: '',
      parent2PhoneNumber: '',
      parent2Email: '',
      gender: '',
      birthYear: 0,
    });
    const showDialog = ref(false);
    const schoolId = computed(() => JSON.parse(localStorage.getItem('school') || '{}')).value.schoolId;

    onMounted(() => {
      fetchViewData()
    });

    const fetchViewData = async () => {
      const response = await fetchStudents();
      students.value = response.data.data;
    };

    const openDialog = () => {
      dialogTitle.value = 'Yeni Öğrenci Ekle';
      currentStudent.value = {
        studentId: 0,
        schoolId: 0,
        schoolNumber: '',
        name: '',
        middleName: '',
        surname: '',
        photoUrl: '',
        description: '',
        createdAt: new Date(),
        parent1FullName: '',
        parent1PhoneNumber: '',
        parent1Email: '',
        parent2FullName: '',
        parent2PhoneNumber: '',
        parent2Email: '',
        gender: '',
        birthYear: 0,
      };
      showDialog.value = true;
    };

    const closeDialog = () => {
      showDialog.value = false;
    };

    const editStudent = (student: StudentDataModel) => {
      dialogTitle.value = 'Öğrenci Güncelle';
      currentStudent.value = { ...student };
      showDialog.value = true;
    };

    const handleSave = async () => {
      if (currentStudent.value.studentId) {
        await updateStudent(currentStudent.value.studentId, currentStudent.value);
      } else {
        currentStudent.value.schoolId = schoolId;
        await saveStudent(currentStudent.value);
      }
      await fetchViewData();
      closeDialog();
    };

    const formatDate = (date: string) => new Date(date).toLocaleDateString();

    // Add Keyboard Shortcuts
    useKeyboardShortcuts([
      { key: 'Escape', action: closeDialog },
      { key: 's', ctrlKey: true, action: async () => await handleSave() },
    ]);


    return {
      students: computed(() => students.value),
      route,
      showDialog,
      currentStudent,
      openDialog,
      closeDialog,
      handleSave,
      formatDate,
      dialogTitle,
      editStudent
    };
  },
});
</script>

<style scoped>
.btn-primary {
  @apply rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500;
}

.btn-secondary {
  @apply rounded-md bg-slate-200 px-4 py-2 text-sm font-semibold text-slate-500 shadow-sm hover:bg-slate-300;
}
</style>
