<template>
    <div class="mb-4">
        <!-- İlk Kart (Normal Exchange) -->
        <div class="card bg-base-100 mb-4 dark shadow">
            <div class="card-body">
                <h2 class="card-title mb-4">Arbitrage Data ({{ data?.SourceExchange }} ➔ {{ data?.TargetExchange }})
                </h2>
                <div>
                    <div class="flex">
                        <div class="w-1/3 mr-4">

                            <div :class="['flex flex-col h-full rounded-xl', themeClass]">
                                <div class="m-2">
                                    <p class="flex justify-between"><strong>Trading Amount:</strong>{{
                                        data?.TradingAmount }} USD</p>
                                    <p class="flex justify-between"><strong>Platform Margin:</strong> {{
                                        data?.PlatformMargin }}</p>
                                    <p class="flex justify-between"><strong>Net Profit Amount: </strong>
                                        <span :class="data?.ProfitAmount > 0 ? 'text-green-500' : 'text-red-500'">
                                            {{ data?.ProfitAmount.toFixed(2) }}
                                        </span>
                                    </p>
                                    <p class="flex justify-between"><strong>Arbitrage Possible: </strong>
                                        <span :class="data?.IsArbitragePossible ? 'text-green-500' : 'text-red-500'">
                                            {{ data?.IsArbitragePossible ? 'Yes' : 'No' }}
                                        </span>
                                    </p>
                                </div>
                                <div class="m-2">
                                    <span class="text-green-500">Buy Order</span>
                                    <p class="flex justify-between"><strong>Source Cost:</strong> {{
                                        data?.SourceCost.toFixed(8) }}</p>
                                    <p class="flex justify-between"><strong>Source Quantity:</strong> {{
                                        data?.SourceQuantity.toFixed(8) }}</p>
                                </div>
                                <div class="m-2">
                                    <span class="text-red-500">Sell Order</span>
                                    <p class="flex justify-between"><strong>Target Sell Price:</strong> {{
                                        data?.TargetSellPrice.toFixed(8) }}</p>
                                    <p class="flex justify-between"><strong>Target Sell Quantity:</strong> {{
                                        data?.TargetSellQuantity.toFixed(8) }}</p>
                                </div>

                                <button :disabled="!data?.IsArbitragePossible"
                                    class="btn btn-accent text-white m-4">Start</button>


                            </div>
                        </div>
                        <!-- Source Bids Tablosu -->
                        <div class="w-1/3">
                            <h3 class="text-lg font-semibold mb-2">Source Bids ({{ data?.SourceExchange }})</h3>
                            <table class="table table-xs">
                                <thead>
                                    <tr>
                                        <th class="px-4">Price</th>
                                        <th class="px-4">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(bid, index) in data?.OrderBook.SourceBids.slice(0, 10)" :key="index">
                                        <td class="border px-4 text-red-500 font-bold">{{ bid.Price }}</td>
                                        <td class="border px-4">{{ bid.Amount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Target Bids Tablosu -->
                        <div class="w-1/3">
                            <h3 class="text-lg font-semibold mb-2">Target Bids ({{ data?.TargetExchange }})</h3>
                            <table class="table table-xs">
                                <thead>
                                    <tr>
                                        <th class="px-4">Price</th>
                                        <th class="px-4">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(bid, index) in data?.OrderBook.TargetBids.slice(0, 10)" :key="index">
                                        <td class="border px-4 text-red-500 font-bold">{{ bid.Price }}</td>
                                        <td class="border px-4">{{ bid.Amount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- İkinci Kart (Ters Exchange) -->
        <div class="card bg-base-100">
            <div class="card-body">
                <h2 class="card-title mb-4">Reversed Arbitrage Data ({{ reversedData?.SourceExchange }} ➔ {{
                    reversedData?.TargetExchange }})</h2>
                <div class="flex justify-between items-start">
                    <div class="mb-4">
                        <p><strong>Trading Amount:</strong> {{ reversedData?.TradingAmount }}</p>
                        <p><strong>Platform Margin:</strong> {{ reversedData?.PlatformMargin }}</p>
                        <p><strong>Net Profit Amount: </strong>
                            <span :class="reversedData?.ProfitAmount > 0 ? 'text-green-500' : 'text-red-500'">
                                {{ reversedData?.ProfitAmount.toFixed(2) }}
                            </span>
                        </p>
                        <p><strong>Arbitrage Possible: </strong>
                            <span :class="reversedData?.IsArbitragePossible ? 'text-green-500' : 'text-red-500'">
                                {{ reversedData?.IsArbitragePossible ? 'Yes' : 'No' }}
                            </span>
                        </p>
                    </div>
                    <div>
                        <p><strong>Source Cost:</strong> {{ reversedData?.SourceCost }}</p>
                        <p><strong>Source Quantity:</strong> {{ reversedData?.SourceQuantity }}</p>
                    </div>
                    <div>
                        <p><strong>Target Sell Price:</strong> {{ reversedData?.TargetSellPrice }}</p>
                        <p><strong>Target Sell Quantity:</strong> {{ reversedData?.TargetSellQuantity }}</p>
                    </div>
                </div>
                <div class="flex space-x-8">
                    <!-- Source Bids Tablosu (Ters Exchange) -->
                    <div class="w-1/2">
                        <h3 class="text-lg font-semibold mb-2">Source Bids ({{ reversedData?.SourceExchange }})</h3>
                        <table class="table table-xs">
                            <thead>
                                <tr>
                                    <th class="px-4 py-2">Price</th>
                                    <th class="px-4 py-2">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(bid, index) in reversedData?.OrderBook.SourceBids.slice(0, 10)"
                                    :key="index">
                                    <td class="border px-4 py-2 text-red-500 font-bold">{{ bid.Price }}</td>
                                    <td class="border px-4 py-2">{{ bid.Amount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Target Bids Tablosu (Ters Exchange) -->
                    <div class="w-1/2">
                        <h3 class="text-lg font-semibold mb-2">Target Bids ({{ reversedData?.TargetExchange }})</h3>
                        <table class="table table-xs">
                            <thead>
                                <tr>
                                    <th class="px-4 py-2">Price</th>
                                    <th class="px-4 py-2">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(bid, index) in reversedData?.OrderBook.TargetBids.slice(0, 10)"
                                    :key="index">
                                    <td class="border px-4 py-2 text-red-500 font-bold">{{ bid.Price }}</td>
                                    <td class="border px-4 py-2">{{ bid.Amount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, watch, PropType, computed, inject } from 'vue';

interface Order {
    Price: number;
    Amount: number;
}

interface OrderBook {
    SourceBids: Order[];
    TargetBids: Order[];
}

interface OrderBookData {
    IsArbitragePossible: boolean;
    TradingAmount: number;
    SourceCost: number;
    TargetSellPrice: number;
    TargetSellQuantity: number;
    PlatformMargin: number;
    ProfitAmount: number;
    Direction: string;
    SourceExchange: string;
    TargetExchange: string;
    OrderBook: OrderBook;
    SourceQuantity: string;
}

export default defineComponent({
    name: 'OrderBook',
    props: {
        symbol: {
            type: String as PropType<string>,
            required: false,
        },
    },
    setup(props) {
        const data = ref<OrderBookData | null>(null);
        const reversedData = ref<OrderBookData | null>(null);
        let ws1: WebSocket;
        let ws2: WebSocket;

        const connectWebSockets = () => {
            // İlk bağlantı: sourceExchange -> targetExchange
            ws1 = new WebSocket(`ws://localhost:5109/ws/orderbook/orderbook?symbol=${props.symbol}&tradingAmount=250000&sourceExchange=binance&targetExchange=bitfinex`);
            ws1.onmessage = (event) => {
                data.value = JSON.parse(event.data) as OrderBookData;
            };
            ws1.onclose = () => {
                console.log(`WebSocket connection closed for ${props.symbol} (binance -> bitfinex)`);
            };

            // İkinci bağlantı: targetExchange -> sourceExchange
            ws2 = new WebSocket(`ws://localhost:5109/ws/orderbook/orderbook?symbol=${props.symbol}&tradingAmount=250000&sourceExchange=bitfinex&targetExchange=binance`);
            ws2.onmessage = (event) => {
                reversedData.value = JSON.parse(event.data) as OrderBookData;
            };
            ws2.onclose = () => {
                console.log(`WebSocket connection closed for ${props.symbol} (bitfinex -> binance)`);
            };
        };

        const closeWebSockets = () => {
            if (ws1) ws1.close();
            if (ws2) ws2.close();
        };

        onMounted(connectWebSockets);

        watch(() => props.symbol, () => {
            closeWebSockets();
            connectWebSockets();
        });


        const profitPercentage = computed(() => {
            const profit = data.value?.ProfitAmount ?? 0;
            return Math.max(0, Math.min(100, profit));
        });

        const theme = inject('theme', ref('light'));
        console.log('Theme in OrderBook:', theme);
        const themeClass = computed(() => {
            return theme.value === 'dark' ? 'bg-slate-700 text-white' : 'bg-gray-50';
        });
        return {
            themeClass,
            data,
            reversedData,
            profitPercentage
        };
    },
});
</script>