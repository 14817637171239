import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '../layouts/MainLayout.vue';
import AuthLayout from '../layouts/AuthLayout.vue';
import Login from '@/views/auth/Login.vue'; 
import Home from '@/views/Home.vue'; 
import Dashboard from '@/views/Dashboard.vue';
import Assistant from '@/views/Assistant.vue';
import BotList from '@/views/BotList.vue';
import SymbolList from '@/views/SymbolList.vue';
import { useAuthStore } from '../stores/auth';
import ExchangeList from '@/views/ExchangeList.vue';
import TradingBotList from '@/views/TradingBotList.vue';
import UserApiKeyList from '@/views/UserApiKeyList.vue';
import UserBalanceList from '@/views/UserBalanceList.vue';
import ClassRoomList from '@/views/ClassRoomList.vue';
import ClassRoomStudentList from '@/views/ClassRoomStudentList.vue';
import ClassRoomStudentDetail from '@/views/ClassRoomStudentDetail.vue';
import SchoolStudentList from '@/views/SchoolStudentList.vue';
import TeacherList from '@/views/TeacherList.vue';
import Settings from '@/views/Settings.vue';
import Help from '@/views/Help.vue';
import TeacherDetail from '@/views/TeacherDetail.vue';
import Membership from '@/views/Membership.vue';
import SettingsOrganization from '@/views/SettingsOrganization.vue';
import PasswordReset from '@/views/auth/PasswordReset.vue';
import NewPassword from '@/views/auth/NewPassword.vue';

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      { path: '', component: Home, props: (route: any) => ({ theme: route.query.theme }), },
      { path: 'dashboard', component: Dashboard},
      { path: 'membership', component: Membership},
      { path: 'bot-list', component: BotList },
      { path: 'symbol-list', component: SymbolList },
      { path: 'exchange-list', component: ExchangeList },
      { path: 'trading-bot-list', component: TradingBotList },
      { path: 'user-api-key-list', component: UserApiKeyList },
      { path: 'user-balance-list', component: UserBalanceList },
      { path: 'chat', component: Assistant },
      { path: 'classroom-list', component: ClassRoomList },
      { path: 'teacher-list', component: TeacherList },
      { path: 'school-student-list', component: SchoolStudentList },
      { path: 'settings', component: Settings},
      { path: 'settingsOrganization', component: SettingsOrganization},
      { path: 'help', component: Help},
      { path: 'teacher/:id', component:TeacherDetail},
      {
        path: '/classRoom/:id',
        name: 'ClassRoomStudentList',
        component: ClassRoomStudentList,
      },
      {
        path: '/classRoom/:id/:studentId',
        name: 'ClassRoomStudentDetail',
        component: ClassRoomStudentDetail,
      }, 
      {
        path: '/okul/:slug',
        name: 'UserDetail',
        component: Assistant,
      },
      // Diğer genel sayfalar
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      { path: 'login', component: Login },
      { path: 'passwordReset', component: PasswordReset},
      { path: 'newPassword', component: NewPassword}
      // Kayıt veya şifre sıfırlama gibi diğer auth sayfaları
    ],
  },
];


const router = createRouter({
  history: createWebHistory(), // HTML5 tarih API'sini kullanarak geçerli bir tarayıcı geçmişi oluşturur.
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('Navigating to:', to.path);
  const authStore = useAuthStore();
  authStore.isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  console.log('User authenticated:', authStore.isAuthenticated);

  if (!authStore.isAuthenticated && !to.path.includes('auth')) {
    console.log('User not authenticated, redirecting to login.');
    next('auth/login');
  } else {
    next();
  }
});

export default router;
