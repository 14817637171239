<template>
  <div class="flex justify-between items-center mb-8">
    <h2 class="text-2xl font-bold">Öğretmen Listesi</h2>
    <div class="flex flex-row gap-2">
      <button @click="fetchViewData"
        class="bg-transparent px-3.5 py-2.5 text-slate-500 hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="h-4 w-4">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
      </button>
      <form @submit.prevent="SearchData">
        <input v-model="searchTerm" placeholder="Arama" class="input-primary" />
        <button type="button" @click.prevent="fetchViewData" class="relative top-1 -left-8">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5  text-slate-400">
            <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
         <button type="submit"
          class="btn-secondary">
          Ara</button>
      </form>
      <button @click="openDialog"
        class="btn-primary">
        Öğretmen Ekleyin</button>
    </div>
  </div>
  <div
    class="relative flex flex-col w-full h-full overflow-scroll text-gray-700 bg-white shadow-md rounded-lg bg-clip-border">
    <table class="w-full text-left table-auto min-w-max text-slate-800">
      <thead>
        <tr class="text-slate-500 border-b border-slate-300 bg-slate-50">
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              Öğretmen
            </p>
          </th>
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              E-posta Adresi
            </p>
          </th>
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              Durum
            </p>
          </th>
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              YZ Asistanı
            </p>
          </th>
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              Son Veri Giriş Tarihi
            </p>
          </th>
          <th class="p-4">
            <p></p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, classIndex) in Teachers" :key="classIndex" class="hover:bg-slate-50">
          <td class="p-4">
            <p class="text-sm font-bold">
              {{ item.name }} {{ item.surname }}
            </p>
          </td>
          <td class="p-4">
            <p class="text-sm">
              {{ item.email }}
            </p>
          </td>
          <td class="p-4">
            <p :class="{ 'text-green-600': item.isActive, 'text-red-600': !item.isActive }" class="text-sm">
              {{ item.isActive ? 'Aktif' : 'Pasif' }}
            </p>
          </td>
          <td class="p-4">
            <div class="flex flex-row  items-center gap-2">
              <span v-if="item.assistantDetail?.assistantId"
                class="rounded-md bg-green-600 py-0.5 px-2.5 border border-transparent text-sm text-white transition-all shadow-sm">
                OLUŞTURULMUŞ
              </span>
              <span v-else
                class="rounded-md bg-gray-400 py-0.5 px-2.5 border border-transparent text-sm text-white transition-all shadow-sm">
                OLUŞTURULMAMIŞ
              </span>
              <span v-if="item.assistantDetail?.isActive" class="relative flex h-4 w-4">
                <span
                  class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
              </span>
            </div>
          </td>
          <td class="p-4">
            <p class="text-sm">
            </p>
          </td>
          <td class="p-4 flex justify-end flex-row gap-2">
            <button @click="editUser(item)"
              class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Güncelle
            </button>
            <router-link :to="`/teacher/${item.userId}`"
              class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Asistan
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="showDialog" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">{{ dialogTitle }}</h2>
        <button @click="closeDialog" class="text-red-500 text-sm">
          Kapat
        </button>
      </div>
      <div class="flex flex-row gap-4">
        <div>
          <label for="name" class="block text-sm font-medium text-gray-700">Ad</label>
          <input v-model="Teacher.name" placeholder="Ad" class="input input-bordered" />
        </div>
        <div>
          <label for="surname" class="block text-sm font-medium text-gray-700">Soyad</label>
          <input v-model="Teacher.surname" placeholder="Soyad" class="input input-bordered" />
        </div>
      </div>
      <div class="my-4">
        <label for="email" class="block text-sm font-medium text-gray-700">E-posta Adresi</label>
        <input v-model="Teacher.email" placeholder="E-posta adresi" class="input input-bordered w-full" />
      </div>
      <div class="mb-4">
        <label for="description" class="text-sm font-medium text-gray-700">Durum</label>
        <input v-model="Teacher.isActive" id="isActive" type="checkbox"
          class="ml-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
      </div>
      <div class="flex justify-end">
        <button @click="handleSave"
          class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          {{ Teacher.userId ? 'Güncelle' : 'Kaydet' }}
        </button>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { getTeachers, createTeacher, updateTeacher, searchTeacher } from '@/services/teacherService';
import { Teacher, AssistantDto } from '@/models/models';
import { toast } from 'vue3-toastify';

export default defineComponent({
  name: 'TeacherList',
  components: {},
  setup() {
    const Teachers = ref<Teacher[]>([]);
    const Teacher = ref<Teacher>({
      userId: 0,
      schoolId: 0,
      name: '',
      surname: '',
      email: '',
      assistantDetail: {} as AssistantDto,
      role: '',
      createdAt: new Date(),
      isActive: true
    });
    const route = useRoute();
    const dialogTitle = ref('');
    const searchTerm = ref('');
    const showDialog = ref(false);

    onMounted(() => {
      fetchViewData()
    });

    const schoolId = computed(() => JSON.parse(localStorage.getItem('school') || '{}')).value.schoolId;

    const fetchViewData = async () => {
      searchTerm.value = '';
      const response = await getTeachers();
      Teachers.value = response.data.data;
    };

    const SearchData = async () => {
      const response = await searchTeacher(searchTerm.value);
      Teachers.value = response.data.data;
    };

    const handleSave = async () => {
      if (Teacher.value.userId) {
        const response = await updateTeacher(Teacher.value.userId, Teacher.value);
        if (response.data.success) {
          toast.success(response.data.message);
          closeDialog();
        }
        else {
          toast.error(response.data.message);
        }
      } else {
        Teacher.value.schoolId = schoolId;
        const response = await createTeacher(Teacher.value);
        if (response.data.success) {
          toast.success(response.data.message);
          closeDialog();
        }
        else {
          toast.error(response.data.message);
        }
      }
      await fetchViewData();
      closeDialog();
    };




    const editUser = (user: Teacher) => {
      dialogTitle.value = 'Öğretmen Güncelle';
      Teacher.value = { ...user };
      showDialog.value = true;
    };

    const openDialog = () => {
      dialogTitle.value = 'Yeni Öğretmen Ekle';
      showDialog.value = true;
      Teacher.value = {
        userId: 0,
        schoolId: 0,
        name: '',
        surname: '',
        email: '',
        assistantDetail: {} as AssistantDto,
        role: '',
        createdAt: new Date(),
        isActive: true
      };
    };

    const closeDialog = () => {
      showDialog.value = false;
    };

    return {
      fetchViewData,
      Teachers,
      Teacher,
      route,
      showDialog,
      openDialog,
      closeDialog,
      handleSave,
      editUser,
      dialogTitle,
      SearchData,
      searchTerm
    };
  },
});
</script>

<style scoped>
.btn-primary {
  @apply rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500;
}

.btn-secondary {
  @apply rounded-md bg-slate-200 px-4 py-2 text-sm font-semibold text-slate-500 shadow-sm hover:bg-slate-300;
}

.input-primary {
  @apply rounded-md px-4 py-2 text-sm font-semibold shadow-sm;
}
</style>
