<!-- src/layouts/MainLayout.vue -->
<template>
    <div :data-theme="theme">
        <div class="relative z-50">
            <Sidebar />
            <div class="mx-auto lg:ml-80 dark:bg-black bg-slate-100">
                <NavBar @toggleSidebar="sidebarVisible = !sidebarVisible" />
                <main class="p-4">
                    <div class="flex flex-col pb-12">
                        <router-view></router-view>
                    </div>
                </main>
              
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, provide } from 'vue';
import Sidebar from '../components/layout/Sidebar.vue';
import NavBar from '../components/layout/NavBar.vue';

export default defineComponent({
    name: 'MainLayout',
    components: {
        Sidebar,
        NavBar,
    },
    setup() {
        const sidebarVisible = ref(true)

        const theme = ref('light'); // Varsayılan olarak 'light' teması

        //Tema sınıfını hesaplayan computed
        //const themeClass = computed(() => theme.value === 'dark' ? 'dark bg-gray-600 text-white' : 'light bg-gray-100 text-black'));

        // Temalar arasında geçişi sağlayan fonksiyon
        const toggleTheme = () => {
            theme.value = theme.value === 'light' ? 'dark' : 'light';
        };

        // `provide` ile `theme` ve `toggleTheme` fonksiyonunu alt bileşenlere gönderiyoruz
        provide('theme', theme);
        provide('toggleTheme', toggleTheme);

        return {
            theme,
            toggleTheme,
            sidebarVisible
        };
    },
});
</script>
