<template>
  <div class="font-bold text-xl pb-8">
    Bot List
  </div>

  <div class="">

    <div class="stats flex stats-vertical lg:stats-horizontal shadow">
      <div class="stat">
        <div class="stat-title">Total Buy</div>
        <div class="stat-value">1.250.000 USD</div>
        <div class="stat-desc">Jan 1st - Feb 1st</div>
      </div>

      <div class="stat">
        <div class="stat-title">Total Sell</div>
        <div class="stat-value">1.300.000 USD</div>
        <div class="stat-desc">↗︎ 400 (22%)</div>
      </div>

      <div class="stat">
        <div class="stat-title">Profit</div>
        <div class="stat-value">50.000 USD</div>
        <div class="stat-desc">↘︎ 90 (14%)</div>
      </div>
    </div>

    <div class="stats stats-vertical block shadow mt-8">
      <table class="table table-xs">
        <!-- MonitorItem -->
        <thead>
          <tr>
            <th></th>
            <th>Order Book</th>
            <th>Target</th>
            <th>Source</th>
            <th>Estimated Result</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in monitorItems.data" :key="index">
            <th>{{ index + 1 }}</th>
            <td>{{ monitorItems.orderBook }}</td>
            <td>
              <div>
                <div class="stat-title font-bold">{{ item.target.platform }}</div>
                <div class="stat-title">Balance: {{ item.target.balance }} USD</div>
                <div class="stat-title">Price: {{ item.target.price }} USD</div>
                <div class="stat-title">Quantity: {{ item.target.maxQuantity.toFixed(5) }}</div>
                <div class="stat-title">Transaction: {{ item.target.type }}</div>
              </div>
            </td>
            <td>
              <div>
                <div class="stat-title font-bold">{{ item.source.platform }}</div>
                <div class="stat-title">Balance: {{ item.source.balance }} USD</div>
                <div class="stat-title">Price: {{ item.source.price }} USD</div>
                <div class="stat-title">Quantity: {{ item.source.maxQuantity.toFixed(5) }}</div>
                <div class="stat-title">Transaction: {{ item.source.type }}</div>
              </div>
            </td>
            <td>
              <div class="stat-title">Quantity: {{ findMinQuantity(item.source.maxQuantity,
                item.target.maxQuantity).toFixed(5) }}</div>
              <div class="stat-title">Net Profit: {{ item.estimatedResult.netProfit.toFixed(2) }} USD </div>
              <div class="stat-title">Ratio: {{ item.estimatedResult.profitRatio }}</div>
              <div class="stat-desc">Calculation Time: 15 seconds ago</div>
            </td>
            <td>
              <progress class="progress progress-error" value="100"></progress>
              <ul class="timeline timeline-vertical">
                <li>
                  <div class="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-green-400">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="timeline-end timeline-box">
                    Sell Order Created
                    <p class="font-bold text-red-400">Price: 12.10 USD</p>
                    <p class="text-[10px]">28.11.2024 14:25:456</p>
                  </div>
                  <hr />
                </li>
                <li>
                  <hr />
                  <div class="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-green-400">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="timeline-end timeline-box">
                    Sell Order Finished
                    <p class="text-[10px]">28.11.2024 14:25:456</p>
                  </div>
                  <hr />
                </li>
               
              </ul>
            </td>

            <td>
              <progress class="progress progress-accent"></progress>
              <ul class="timeline timeline-vertical">
                <li>
                  <hr />
                  <div class="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-green-400">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="timeline-end timeline-box">
                    Buy Order Created  
                    <p class="font-bold text-green-400">Price: 12.10 USD</p>
                    <p class="text-[10px]">28.11.2024 14:25:456</p>
                  </div>
                  <hr />
                </li>
                <li>
                  <hr />
                  <div class="timeline-middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5">
                      <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="timeline-end timeline-box">Buy Order Finished
                    <p class="text-[10px]">28.11.2024 14:25:456</p>
                  </div>
                  <hr />
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'BotList',
  components: {
  },
  setup() {

    interface DataItem {
      platform: string;
      balance: number;
      price: number;
      commission: number;
      type: 'sell' | 'buy';
      maxQuantity: number;
    }

    interface DataEntry {
      target: DataItem;
      source: DataItem;
      estimatedResult: EstimatedResult;
    }

    interface EstimatedResult {
      profitRatio: number;
      maxQuantity: number;
      netProfit: number;
    }

    interface MonitorItem {
      orderBook: string;
      targetProfitRate: number;
      data: DataEntry[];
    }

    const monitorItems = ref<MonitorItem>({
      orderBook: 'USDT:USD',
      targetProfitRate: 0.0001,
      data: [
        {
          target: {
            platform: 'Binance',
            balance: 100000,
            price: 10.06,
            commission: 0.0001,
            type: 'sell',
            maxQuantity: 0
          },
          source: {
            platform: 'Bitflex',
            balance: 50000,
            price: 10.02,
            commission: 0.0005,
            type: 'buy',
            maxQuantity: 0
          },
          estimatedResult: {
            netProfit: 89,
            maxQuantity: 4990,
            profitRatio: 0.00298
          }
        },
        {
          target: {
            platform: 'Binance',
            balance: 100000,
            price: 10.06,
            commission: 0.0001,
            type: 'sell',
            maxQuantity: 0
          },
          source: {
            platform: 'Bitflex',
            balance: 50000,
            price: 10.02,
            commission: 0.0005,
            type: 'buy',
            maxQuantity: 0
          },
          estimatedResult: {
            netProfit: 89,
            maxQuantity: 4990,
            profitRatio: 0.00298
          }
        },
        {
          target: {
            platform: 'Binance',
            balance: 100000,
            price: 10.06,
            commission: 0.0001,
            type: 'sell',
            maxQuantity: 0
          },
          source: {
            platform: 'Bitflex',
            balance: 50000,
            price: 10.02,
            commission: 0.0005,
            type: 'buy',
            maxQuantity: 0
          },
          estimatedResult: {
            netProfit: 89,
            maxQuantity: 4990,
            profitRatio: 0.00298
          }
        },
        {
          target: {
            platform: 'Binance',
            balance: 100000,
            price: 10.06,
            commission: 0.0001,
            type: 'sell',
            maxQuantity: 0
          },
          source: {
            platform: 'Bitflex',
            balance: 50000,
            price: 10.02,
            commission: 0.0005,
            type: 'buy',
            maxQuantity: 0
          },
          estimatedResult: {
            netProfit: 89,
            maxQuantity: 4990,
            profitRatio: 0.00298
          }
        }
      ]

    });

    const calculateMaxQuantity = () => {
      monitorItems.value.data.forEach((entry) => {
        entry.target.maxQuantity = entry.target.balance / entry.target.price;
        entry.source.maxQuantity = entry.source.balance / entry.source.price;
      });
    };


    const findMinQuantity = (a: number, b: number): number => {
      return a < b ? a : b;
    };

    onMounted(() => {
      calculateMaxQuantity();
    });

    return {
      monitorItems,
      findMinQuantity
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
