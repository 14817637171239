<template>

  <div class="flex justify-between items-center mb-8">

    <h2 class="text-2xl font-bold">{{ ClassRoom?.name }} Öğrenci Listesi</h2>

    
    <div class="flex flex-row gap-2">
      <a href="#"
        class="rounded-md bg-slate-200 px-3.5 py-2.5 text-sm font-semibold text-slate-500 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        Arşiv</a>
      <button @click="openSettingsDialog"
        class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        Sınıfa Öğrenci Ekleyin</button>
    </div>
  </div>
  <div
    class="relative flex flex-col w-full h-full overflow-scroll text-gray-700 bg-white shadow-md rounded-lg bg-clip-border">
    <table class="w-full text-left table-auto min-w-max text-slate-800">
      <thead>
        <tr class="text-slate-500 border-b border-slate-300 bg-slate-50">
          <th class="p-4 w-24">

          </th>
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              Öğrenci
            </p>
          </th>
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              Okul Numarası
            </p>
          </th>
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              Kayıt Tarihi
            </p>
          </th>
          <th class="p-4">
            <p class="text-sm leading-none font-normal">
              Son Veri Giriş Tarihi
            </p>
          </th>
          <th class="p-4">
            <p></p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, classIndex) in Students" :key="classIndex" class="hover:bg-slate-50">
          <td class="p-4 w-10 ">
            <Avatar :photoUrl="item.photoUrl" :name="item.name" :surname="item.surname" />
          </td>
          <td class="p-4">
            <p class="text-sm font-bold">
              {{ item.name }} {{ item.middleName }} {{ item.surname }}
            </p>
          </td>
          <td class="p-4">
            <p class="text-sm">
              {{ item.schoolNumber }}
            </p>
          </td>
          <td class="p-4">
            <p class="text-sm">
              {{ item.createdAt }}
            </p>
          </td>
          <td class="p-4">
            <p class="text-sm">
              {{ item.lastDataEntryDate }}
            </p>
          </td>
          <td class="p-4 text-right">
            <router-link :to="`/classRoom/${route.params.id}/${item.studentId}`"
              class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Veri Girişi
            </router-link>

            <router-link :to="`/chat?crid=${route.params.id}&sid=${item.studentId}`"
              class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              CHAT
            </router-link>
          </td>
        </tr>
        <tr>
                <td v-if="Students.length == 0" colspan="6" class="p-4 text-center h-32">
                  Bu sınıfa henüz öğrenci kayıt edilmemiş.
                </td>
              </tr>
      </tbody>
    </table>
  </div>

  <div v-if="showSettingsDialog" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">Yeni Öğrenci</h2>
        <button @click="closeSettingsDialog" class="text-red-500 text-sm">
          Kapat
        </button>
      </div>
      <input v-model="Student.schoolNumber" placeholder="Okul Numarası" class="input input-bordered w-full mb-4" />

      <div class="flex justify-between">
        <button
          class="rounded-md px-3.5 py-2.5 text-sm font-semibold text-red-500 hover: bg-slate-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Arşivle</button>
        <button @click="AddStudentToClassRoom"
          class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kaydet</button>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getClassRoomById, getClassRoomStudents, addStudentToClassRoom } from '@/services/classRoomService';
import { ClassRoomStudentDto } from '@/models/models';
import { toast } from 'vue3-toastify';
import Avatar from '@/components/ui/Avatar.vue';

interface Student {
  studentId: number;
  schoolId: number;
  schoolNumber: string;
  name: string;
  middleName: string;
  surname: string;
  photoUrl: string;
  description: string;
  createdAt: Date;
}

export default defineComponent({

  name: 'ClassRoomStudentList',
  components: {
    Avatar
  },
  setup() {
    const ClassRoom = ref<any>(null);
    const Students = ref<ClassRoomStudentDto[]>([]);
    const Student = ref<Student>({
      studentId: 0,
      schoolId: 0,
      schoolNumber: '',
      name: '',
      middleName: '',
      surname: '',
      photoUrl: '',
      description: '',
      createdAt: new Date(),
    });
    const route = useRoute();
    const showSettingsDialog = ref(false);

    onMounted(() => {
      fetchViewData()
    });
    
    const fetchViewData = async () => {

      const classRoom = await getClassRoomById(Number(route.params.id));
      ClassRoom.value = classRoom.data.data;

      const response = await getClassRoomStudents(Number(route.params.id));
      Students.value = response.data.data;
    };

    const AddStudentToClassRoom = async () => {
      try {
        const response = await addStudentToClassRoom(Number(route.params.id), Student.value.schoolNumber);
        if (response.data.success) {
         toast.success(response.data.message);
        }
        else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error posting student data:', error);
      }
      fetchViewData();
    };

    const openSettingsDialog = () => {
      showSettingsDialog.value = true;
    };

    const closeSettingsDialog = () => {
      showSettingsDialog.value = false;
    };

    return {
      ClassRoom,
      Students,
      Student,
      route,
      showSettingsDialog,
      openSettingsDialog,
      closeSettingsDialog,
      AddStudentToClassRoom,
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
