<template>

  <Sheet ref="sheetRef">
    <h4 class="font-bold my-4">{{ isEditing ? 'Update Symbol' : 'Add Symbol' }}</h4>

    <form @submit.prevent="editingSymbol ? updateSymbol() : addSymbol()">
      <input v-model="editingSymbol.symbolName" placeholder="Symbol Name" class="input input-bordered w-full mb-2" />
      <input v-model="editingSymbol.baseAsset" placeholder="Base Asset" class="input input-bordered w-full mb-2" />
      <input v-model="editingSymbol.quoteAsset" placeholder="Quote Asset" class="input input-bordered w-full mb-2" />
      <button type="submit" class="btn btn-primary w-full">{{ isEditing ? 'Update' : 'Add' }}</button>
    </form>
  </Sheet>


  <div>
    <h2 class="text-2xl font-bold mb-4">Symbol Management</h2>
    <!-- Modal -->
    <input id="my-modal" type="checkbox" class="modal-toggle" />
    <div class="modal">
      <div class="modal-box">
        <h3 class="font-bold">Modal</h3>
        <p>Moda Başlığı</p>
        <div class="modal-action">
          <label for="my-modal" class="btn">Close</label>
        </div>
      </div>
    </div>
    <div class="p-6 bg-white rounded-lg shadow-md">
  <!-- Card Header -->
  <div class="flex justify-between items-center mb-4">
    <h2 class="text-xl font-bold text-gray-800">Symbols</h2>
    <div class="space-x-2">
      <button @click="openSheet" class="inline-flex h-9 py-1 px-4 items-center text-sm font-bold text-white bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg">Add New</button>
      <label for="my-modal" class="inline-flex h-9 py-1 px-4 items-center text-sm font-bold text-gray-600 bg-gray-100 hover:bg-gray-200 transition duration-200 rounded-lg">Export</label>
    </div>
  </div>
  <!-- Card Body -->
  <div class="overflow-x-auto">
    <table class="min-w-full bg-white">
      <thead>
        <tr class="bg-gray-100">
          <th class="py-3 px-4 text-left text-sm font-semibold text-gray-600">Symbol</th>
          <th class="py-3 px-4 text-left text-sm font-semibold text-gray-600">Base Asset</th>
          <th class="py-3 px-4 text-left text-sm font-semibold text-gray-600">Quate Asset</th>
          <th class="py-3 px-4 text-left text-sm font-semibold text-gray-600">Status</th>
          <th class="py-3 px-4 text-left text-sm font-semibold text-gray-600">Actions</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr v-for="symbol in symbols" :key="symbol.symbolId">
          <td class="py-3 px-4 text-sm text-gray-800">{{ symbol.symbolName }}</td>
          <td class="py-3 px-4 text-sm text-gray-800">{{ symbol.baseAsset }}</td>
          <td class="py-3 px-4 text-sm text-gray-800">{{ symbol.quoteAsset }}</td>
          <td class="py-3 px-4 text-sm text-gray-800">
            <span class="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">Active</span>
          </td>
          <td class="py-3 px-4 text-sm text-gray-800">
            <button @click="editSymbol(symbol)" class="text-blue-500 hover:text-blue-700 mr-2">Edit</button>
            <button @click="deleteSymbol(symbol.SymbolId)" class="text-red-500 hover:text-red-700">Delete</button>
          </td>
        </tr>
        <!-- More rows can be added here -->
      </tbody>
    </table>
  </div>
  <!-- Card Footer with Pagination -->
  <div class="flex items-center justify-between mt-4">
    <div class="text-sm text-gray-600">Showing 1 to 1 of 1 entries</div>
    <div class="flex space-x-2">
      <button class="px-3 py-1 text-sm text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 disabled:opacity-50">Previous</button>
      <button class="px-3 py-1 text-sm text-white bg-blue-500 rounded-lg hover:bg-blue-600">1</button>
      <button class="px-3 py-1 text-sm text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 disabled:opacity-50">Next</button>
    </div>
  </div>
</div>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import apiClient from '@/axios';
import Sheet from '@/components/ui/Sheet.vue';
import { SymbolModel } from '@/models/SymbolModel';

export default defineComponent({
  name: 'SymbolCrud',
  components: { Sheet },
  setup() {
    const isEditing = ref(false);
    const symbols = ref<SymbolModel[]>([]);
    const newSymbol = ref<SymbolModel>({ symbolId: 0, symbolName: '', baseAsset: '', quoteAsset: '' });
    const editingSymbol = ref<SymbolModel | null>(null);
    const sheetRef = ref<InstanceType<typeof Sheet> | null>(null);

    const openSheet = () => {
      sheetRef.value?.openSheet();
    };

    // Symbol listesini alma
    const fetchSymbols = async () => {
      const response = await apiClient.get('/symbol');
      console.log(response.data.data);
      symbols.value = response.data.data;
      sheetRef.value?.closeSheet();
    };

    // Yeni symbol ekleme
    const addSymbol = async () => {
      isEditing.value = false;
      sheetRef.value?.openSheet();
      await apiClient.post('/symbol', newSymbol.value);
      newSymbol.value = { symbolId: 0, symbolName: '', baseAsset: '', quoteAsset: '' };
      fetchSymbols();
    };

    // Symbol düzenleme
    const editSymbol = (symbol: SymbolModel) => {
      editingSymbol.value = { ...symbol };
      isEditing.value = true;
      sheetRef.value?.openSheet();
    };

    // Symbol güncelleme
    const updateSymbol = async () => {
      if (editingSymbol.value) {
        await apiClient.put(`/symbol/${editingSymbol.value.symbolId}`, editingSymbol.value);
      }
      isEditing.value = false;
      fetchSymbols();
    };

    // Symbol silme
    const deleteSymbol = async (id: number) => {
      await apiClient.delete(`/symbol/${id}`);
      fetchSymbols();
    };

    onMounted(() => {
      fetchSymbols();
    });

    return {
      isEditing,
      symbols,
      newSymbol,
      editingSymbol,
      sheetRef,
      openSheet,
      addSymbol,
      editSymbol,
      updateSymbol,
      deleteSymbol,
    };
  },
});
</script>