<template>
    <div>
        <div class="relative my-4">
            <div v-if="photoUrl">
                <img :src="photoUrl" alt="Avatar" class="relative rounded-full w-16 h-16" />
            </div>
            <div v-else
                class="relative inline-flex items-center justify-center w-16 h-16 text-xl text-white bg-green-300 rounded-full">
                {{ initials }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'Avatar',
    props: {
        photoUrl: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        surname: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        // Initials are computed by taking the first letter of name and surname
        const initials = computed(() => {
            const nameInitial = props.name?.charAt(0).toUpperCase() || '';
            const surnameInitial = props.surname?.charAt(0).toUpperCase() || '';
            return `${nameInitial}${surnameInitial}`;
        });

        return {
            initials,
        };
    },
});
</script>

<style scoped></style>