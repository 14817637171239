<template>


  <div x-data="{ mobileNavOpen: false }">
    <nav class="lg:hidden py-6 px-6 border-b bg-white">
      <div class="flex items-center justify-between">
        <a class="text-2xl font-semibold" href="#">
          SCHOOLMEET.AI
        </a>
        <button x-on:click="mobileNavOpen = !mobileNavOpen" class="flex items-center rounded focus:outline-none">
          <svg class="text-white bg-indigo-500 hover:bg-indigo-600 block h-8 w-8 p-2 rounded" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <title>Mobile menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
      </div>
    </nav>
    <div :class="{ 'block': mobileNavOpen, 'hidden': !mobileNavOpen }" class="hidden relative z-50">
      <div x-on:click="mobileNavOpen = !mobileNavOpen" class="fixed lg:hidden inset-0 bg-gray-800 opacity-10"></div>
      <nav
        class="fixed top-0 left-0 bottom-0 flex flex-col w-3/4 lg:w-80 sm:max-w-xs pt-6 pb-8 bg-white border-r overflow-y-auto">
        <div class="flex w-full items-center px-6 pb-6 mb-6 lg:border-b border-blue-50">
          <a class="text-xl font-semibold" href="#">
            <img class="h-8" src="artemis-assets/logos/artemis-logo-light.svg" alt="" width="auto">
          </a>
        </div>
        <div class="px-4 pb-6">
          <h3 class="mb-2 text-xs uppercase text-gray-200 font-medium">Main</h3>
          <ul class="mb-8 text-sm font-medium">
            <li v-for="item in menuItems" :key="item.name">
              <router-link :to="item.path" class="flex items-center pl-3 py-3 pr-4 text-white bg-indigo-500 rounded"
                active-class="flex items-center pl-3 py-3 pr-4 text-white bg-indigo-500 rounded">
                <span>{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="hidden lg:block relative z-50">
      <div class="fixed lg:hidden inset-0 bg-gray-800 opacity-10"></div>
      <nav
        class="fixed top-0 left-0 bottom-0 flex flex-col w-3/4 lg:w-80 sm:max-w-xs border-r overflow-y-auto bg-white">
        <div class="flex w-full items-center px-6 py-6 mb-6 lg:border-b border-blue-50  bg-gradient-to-r from-purple-200 via-pink-50 to-indigo-100">
          <span class="text-xl font-bold" href="#">
            SCHOOLMEET.AI
          </span>
        </div>
       
        <div class="px-4 pb-4">
          <ul class="mb-8 text-sm font-medium">
            <li v-for="item in menuItems" :key="item.name">
              <router-link :to="item.path"
                class="flex items-center mt-1 pl-3 py-3 pr-2 text-gray-700 hover:bg-indigo-50 rounded"
                exact-active-class=" text-white bg-indigo-500 hover:text-black">
                <span>{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div class="px-4 pb-4">
           <span class="font-bold text-sm">YÖNETİCİ İŞLEMLERİ</span> 
          <ul class="mb-8 text-sm font-medium">
            <li v-for="item in adminMenuItems" :key="item.name">
              <router-link :to="item.path"
                class="flex items-center mt-1 pl-3 py-3 pr-2 text-gray-700 hover:bg-indigo-50 rounded"
                exact-active-class=" text-white bg-indigo-500 hover:text-black">
                <span>{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>

      <footer class="fixed bottom-0 lg:w-80 border-t border-r bg-slate-50 text-center py-2 pl-4 flex flex-row">
        <span class="text-sm text-gray-500">Made with </span>
        <svg class="h-4 w-4 text-red-500 fill-current mx-2" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z">
          </path>
        </svg>
        <div class="flex flex-row flex-1 justify-between">

          <span class="text-sm text-gray-500">in İstanbul</span> 
          <span class="text-sm text-gray-500 pr-4">
            v{{VUE_APP_VERSION}}
          </span>
        </div>
      </footer>
    </div>
    <div class="mx-auto lg:ml-80"></div>
  </div>

  
</template>

<script lang="ts">
import { defineComponent, computed, inject, Ref } from 'vue';

export default defineComponent({
  name: 'Sidebar',
  setup() {
    // `App.vue` gibi üst bir bileşende sağlanan tema durumu
    const theme = inject('theme') as Ref<string>;
    const VUE_APP_VERSION = process.env.VUE_APP_VERSION;
    // Temaya göre uygun sınıfı belirlemek için computed
    const themeClass = computed(() => (theme.value === 'dark' ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black'));

    // Örnek menü öğeleri
    const menuItems = [
      { name: 'Ana Sayfa', path: '/dashboard' },
      //{ name: 'Bot List', path: '/bot-list' },
      //{ name: 'Trading Bot List', path: '/trading-bot-list' },
      //{ name: 'Exchange List', path: '/exchange-list' },
      //{ name: 'Symbol List', path: '/symbol-list' },
      //{ name: 'Balances', path: '/user-balance-list' },
      //{ name: 'Api Key List', path: '/user-api-key-list' },
      { name: 'Yapay Zeka Asistanım', path: '/teacher/0' },
      { name: 'Sınıflar', path: '/classroom-list' },
      { name: 'Ayarlar', path: '/settings' },
      { name: 'Yardım', path: '/help' },
    ]

    const adminMenuItems = [
      { name: 'Kurum Ayarları', path: '/settingsOrganization' },
      { name: 'Öğretmen Listesi', path: '/teacher-list' },
      { name: 'Öğrenci Listesi', path: '/school-student-list' },
      { name: 'Abonelik Yönetimi', path: '/membership' },
    ]

    return {
      themeClass,
      menuItems,
      adminMenuItems,
      VUE_APP_VERSION
    };
  },
});
</script>

<style scoped>
/* Ek stiller (isteğe bağlı) */
</style>