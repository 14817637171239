<template>
  <div class="flex justify-between items-center mb-8">
    <h2 class="text-2xl font-bold">Sınıflar</h2>
    <div v-if="classRooms.length > 0" class="flex flex-row gap-2">
      <button @click="openCreateDialog" class="btn-primary">Sınıf Ekleyin</button>
    </div>
  </div>
  <div v-if="!Loading">
    <div v-if="classRooms.length == 0"
      class="flex flex-col items-center justify-center space-y-4 bg-white rounded-md shadow-sm p-4 h-96">
      <h2 class="text-2xl font-bold mb-4">Sınıf Ekleyin</h2>
      <a href="#" class="btn-primary">
        Sınıf Ekleyin</a>
      <div class="text-center max-w-md">
        <span class="text-gray-600 text-sm">
          Yeni bir sınıf ekleyin ve daha sonra öğrencilerinizi sınıflara ekleyin.
        </span>
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
      <div v-for="(item, classIndex) in classRooms.filter(a => a.isActive)" :key="classIndex" class="bg-white rounded-md shadow-sm p-4">
        <h3 class="text-lg font-semibold text-gray-800">{{ item.name }}</h3>
        <p class="text-sm text-gray-600">{{ item.description }}</p>
        <div class="flex justify-between items-center mt-4">
          <button @click="editClassRoom(item)" class="text-orange-600 font-semibold">Düzenle</button>
          <router-link :to="`/classRoom/${item.classRoomId}`" class="text-indigo-600 font-semibold">Öğrenci
            Listesi</router-link>
        </div>
      </div>
    </div>
    <h2 class="text-xl font-semibold pt-4">Pasif Sınıflar</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
      <div v-for="(item, classIndex) in classRooms.filter(a => !a.isActive)" :key="classIndex" class="bg-white rounded-md shadow-sm p-4">
        <h3 class="text-lg font-semibold text-gray-800">{{ item.name }}</h3>
        <p class="text-sm text-gray-600">{{ item.description }}</p>
        <div class="flex justify-between items-center mt-4">
          <button @click="editClassRoom(item)" class="text-orange-600 font-semibold">Düzenle</button>
          <router-link :to="`/classRoom/${item.classRoomId}`" class="text-indigo-600 font-semibold">Öğrenci
            Listesi</router-link>
        </div>
      </div>
    </div>
    <ClassRoomDialog v-if="showDialog" :classRoom="currentClassRoom" :dialogTitle="dialogTitle" @save="handleSave"
      @close="closeDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getClassRoomsByTeacherId, createClassRoom, updateClassRoom } from '@/services/classRoomService';
import { ClassRoom } from '@/models/models';
import ClassRoomDialog from '@/components/ClassRoomDialog.vue';
import { toast } from 'vue3-toastify';

export default defineComponent({
  name: 'ClassRoomList',
  components: {
    ClassRoomDialog
  },
  setup() {
    const Loading = ref<boolean>(true);
    const route = useRoute();
    const classRooms = ref<ClassRoom[]>([]);
    const showDialog = ref(false);
    const dialogTitle = ref('');
    const currentClassRoom = ref<ClassRoom | null>(null);

    onMounted(() => {
      fetchViewData();
    });

    const fetchViewData = async () => {
      try {
        const response = await getClassRoomsByTeacherId();
        classRooms.value = response.data.data;
      } catch (error) {
        console.error('Error fetching class rooms:', error);
      }
      Loading.value = false;
    };

    const openCreateDialog = () => {
      dialogTitle.value = 'Yeni Sınıf';
      currentClassRoom.value = null;
      showDialog.value = true;
    };

    const editClassRoom = (classRoom: ClassRoom) => {
      dialogTitle.value = 'Sınıf Güncelle';
      currentClassRoom.value = { ...classRoom };
      showDialog.value = true;
    };

    const handleSave = async (classRoom: ClassRoom) => {
      try {
        if (classRoom.classRoomId) {
          const response = await updateClassRoom(classRoom.classRoomId, classRoom);
          if (response.data.success) {
            toast.success(response.data.message);
          }
          else {
            toast.error(response.data.message);
          }
        } else {
          const response = await createClassRoom(classRoom);
          if (response.data.success) {
            toast.success(response.data.message);
          }
          else {
            toast.error(response.data.message);
          }
        }
        fetchViewData();
        closeDialog();
      } catch (error) {
        console.error('Error saving class room:', error);
      }
    };

    const closeDialog = () => {
      showDialog.value = false;
    };

    return {
      Loading,
      route,
      classRooms,
      showDialog,
      dialogTitle,
      currentClassRoom,
      openCreateDialog,
      editClassRoom,
      handleSave,
      closeDialog,
    };
  },
});
</script>

<style scoped>
.btn-primary {
  @apply rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500;
}

.btn-secondary {
  @apply rounded-md bg-slate-200 px-4 py-2 text-sm font-semibold text-slate-500 shadow-sm hover:bg-slate-300;
}
</style>