import { computed } from 'vue';
import { ClassRoom } from '@/models/models';
import apiClient from '@/axios';

const schoolId = computed(() => {
    const schoolData = JSON.parse(localStorage.getItem('school') || '{}');
    return schoolData?.value?.schoolId || 0;
  });

const userId = computed(() => JSON.parse(localStorage.getItem('user') || '{}')).value.userId;


export const getClassRoomById = (id: number) => apiClient.get(`/classroom/${id}`);
export const getClassRoomsByTeacherId = () => apiClient.get(`/classroom/teacher/${userId}`);
export const createClassRoom = (data: Partial<ClassRoom>) => apiClient.post('/classroom', data);
export const updateClassRoom = (id: number, data: Partial<ClassRoom>) => apiClient.put(`/classroom/${id}`, data);

export const getClassRoomStudents = (id: number) => apiClient.get(`/classroom/${id}/students`);
export const addStudentToClassRoom = (classRoomId: number, schoolNumber: string) => apiClient.post(`/classroom/${classRoomId}/student/${schoolNumber}`);