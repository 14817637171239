<template>
  <div class="flex justify-between items-center mb-8">
    <h2 class="text-2xl font-bold">Yapay Zeka Asistanı</h2>
    <div class="flex flex-row gap-2">
    </div>
  </div>
  <div v-if="!Loading" class="flex items-center space-x-4">
    <div class="flex flex-row gap-4 h-screen w-full">
      <div class="w-1/4 p-4 bg-white rounded-md shadow">
        <h2 class="text-2xl font-bold mb-4">{{ Teacher.name }} {{ Teacher.surname }}</h2>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">E-mail</h3>
          <p>{{ Teacher.email }}</p>
        </div>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">Yetki</h3>
          <p>{{ Teacher.role === 'Admin' ? 'Yönetici' : 'Öğretmen' }}</p>
        </div>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">Kayıt Tarihi</h3>
          <p>{{ Teacher.createdAt }}</p>
        </div>
      </div>
      <div class="w-3/4 flex flex-col gap-4">
        <div class=" p-4 bg-white rounded-md shadow">
          <div class="pb-4">
            <div class="flex items-center">
              <svg class="w-12 h-12" fill="url(#grad1)" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style="stop-color:#b794f4;stop-opacity:1" />
                    <stop offset="50%" style="stop-color:#ed64a6;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#f56565;stop-opacity:1" />
                  </linearGradient>
                </defs>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="0"
                  d="M7 0.714966C6.57172 0.714966 6.19841 1.00644 6.09453 1.42193C5.64458 3.22175 5.11525 4.31311 4.3567 5.07167C3.59815 5.83022 2.50678 6.35955 0.706966 6.8095C0.291477 6.91337 -3.32794e-07 7.28669 -3.32794e-07 7.71497C-3.32794e-07 8.14324 0.291477 8.51656 0.706966 8.62043C2.50678 9.07039 3.59815 9.59971 4.3567 10.3583C5.11525 11.1168 5.64458 12.2082 6.09453 14.008C6.19841 14.4235 6.57172 14.715 7 14.715C7.42828 14.715 7.80159 14.4235 7.90547 14.008C8.35542 12.2082 8.88475 11.1168 9.6433 10.3583C10.4019 9.59971 11.4932 9.07039 13.293 8.62043C13.7085 8.51656 14 8.14324 14 7.71497C14 7.28669 13.7085 6.91337 13.293 6.8095C11.4932 6.35955 10.4019 5.83022 9.6433 5.07167C8.88475 4.31311 8.35542 3.22175 7.90547 1.42193C7.80159 1.00644 7.42828 0.714966 7 0.714966Z">
                </path>
              </svg>
              <h2 class="text-lg font-bold pb-4">Yapay Zeka Asistanınız</h2>
            </div>
          </div>
          <div v-if="!Assistant.openAiAssistantId">
            <div
              class="mt-2 mb-4 relative flex w-full p-3 text-sm border border-orange-200 text-slate-600 rounded-md bg-orange-50">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="h-5 w-5 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z">
                </path>
              </svg>
              <span class="font-bold">Yapay asistanınızı henüz aktifleştirmediniz.</span>
            </div>
            <div class="flex justify-end">
              <button @click="CreateAssistant"
                class="rounded-md bg-teal-500 px-6 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-teal-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Aktifleştir
              </button>
            </div>
          </div>
          <div v-else>
            <div class="flex justify-between">
              <p v-if="Assistant.isActive" class="text-2xl font-extrabold text-green-400 pb-4">AKTİF</p>
              <p v-else class="text-2xl font-extrabold text-red-400 pb-4">PASİF</p>
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input type="checkbox" v-model="Assistant.isActive" class="sr-only peer" @change="UpdateAssistant"
                    :checked="Assistant.isActive">
                  <div
                    class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-400 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-400">
                  </div>
                </label>
              </div>
            </div>
            <strong>Assistant Id:</strong> {{ Teacher.assistantDetail.openAiAssistantId }}
          </div>
        </div>
        <template v-if="Assistant">
          <span class="text-2xl font-bold pt-2">Yapay Zeka Asistanınızı Kişiselleştirin</span>
          <div class=" p-4 bg-white rounded-md shadow">
            <h2 class="text-lg font-bold pb-4">Kişisel Talimatlar</h2>
            <textarea rows="4" v-model="Assistant.instruction" placeholder="Asistanınız için kişisel talimatları girin."
              class="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"></textarea>
            <h2 class="text-lg font-bold py-4">Davranış Şekli</h2>
            <textarea rows="4" v-model="Assistant.behaviorStyle" placeholder="Asistanınız davranış şeklini beltirleyin."
              class="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"></textarea>
            <div class="flex justify-end">
              <button @click="UpdateAssistant"
                class="rounded-md bg-indigo-600 px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Güncelle
              </button>
            </div>
          </div>

        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { Teacher, AssistantDto } from '@/models/models';
import { toast } from 'vue3-toastify';
import { getTeacherById, createAssistant, updateAssistant } from '@/services/teacherService';

export default defineComponent({
  name: 'TeacherDetail',
  components: {
  },
  setup() {
    const Loading = ref<boolean>(true);
    const Assistant = ref<AssistantDto>({
      assistantId: 0,
      userId: 0,
      openAiAssistantId: '',
      instruction: '',
      behaviorStyle: '',
      CreatedAt: new Date(),
      isActive: false,
    });
    const Teacher = ref<Teacher>({
      userId: 0,
      schoolId: 0,
      name: '',
      surname: '',
      email: '',
      role: '',
      createdAt: new Date(),
      assistantDetail: Assistant.value,
      isActive: true
    });
    const route = useRoute();
    const message = ref<string>('');
    let userId = computed(() => JSON.parse(localStorage.getItem('user') || '{}')).value.userId;

    onMounted(() => {
      fetchViewData();
    });
    const fetchViewData = async () => {
      if (Number(route.params.id) > 0) {
        userId = Number(route.params.id);
      }
      const responseTeacher = await getTeacherById(userId);
      Teacher.value = responseTeacher.data.data;
      Assistant.value = Teacher.value.assistantDetail;
      Loading.value = false;
    };

    const CreateAssistant = async () => {
      const response = await createAssistant();
      if (response.data.success) {
        toast.success(response.data.message);
        fetchViewData();
      }
    }

    const UpdateAssistant = async () => {
      const response = await updateAssistant(Assistant.value.assistantId, Assistant.value);
      if (response.data.success) {
        toast.success(response.data.message);
        fetchViewData();
      }
    };
    return {
      Loading,
      Teacher,
      Assistant,
      message,
      CreateAssistant,
      UpdateAssistant
    };
  }
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
