<template>
  <div v-if="!Loading" class="relative isolate bg-transparent -top-4">
    <div class="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
      <div class="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)">
      </div>
    </div>
    <div class="py-4 text-2xl font-bold">Ana Sayfa</div>
    <div class="grid grid-cols-3 gap-4">
      <div class="grid grid-flow-row gap-4">
        <div class="p-4 bg-white rounded-md shadow">
          <h2 class="font-semibold pb-4">ScoolMeet.Ai Veli Görüşme Adresi</h2>
          <div class="flex flex-row justify-between bg-slate-50 p-2 border rounded-md items-center">
            <a href="https://schoolmeet.ai/meet/x-okullari" target="_blank" class="text-indigo-600">{{ School.slug }}</a>
            <!-- Component Start -->
            <div class="relative flex flex-col">
              <button @click="copyToClipboard" class="py-2 ml-4 mr-2 tooltip-warning ">
                <svg class="fill-current h-4 w-4 text-gray-600" viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg">
                  <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                    d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z">
                  </path>
                </svg>
              </button>
              <div class="absolute bottom-0 flex flex-col items-center hidden mb-8 group-hover:flex">
                <span
                  class="relative rounded-md z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-slate-600 shadow-lg">
                  Kopyala</span>
                <div class="w-3 h-3 -mt-2 rotate-45 bg-slate-600"></div>
              </div>
            </div>
            <!-- Component End  -->
          </div>

        </div>
        <div class="bg-gradient-to-tr from-[#1507da] to-[#6afac3] rounded-md shadow p-4">
          <div class="flex flex-row justify-between">
            <h2 class="font-semibold text-white">Aktif Yepay Zeka Asistanı</h2>
            <span class="relative flex h-4 w-4">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-4 w-4 bg-green-100"></span>
            </span>
          </div>
          <p class="mt-2 text-5xl font-semibold tracking-tight text-white">{{
            DashboardData.numberOfActiveAiAssistants }}</p>
        </div>
      </div>

      <div class="grid grid-flow-row gap-4">
        <div class="bg-white rounded-md shadow p-4">
          <div class="flex flex-row align-bottom justify-between">
            <h2 class="font-semibold">Öğretmen Sayısı</h2>
            <router-link to="/teacher-list" class="text-indigo-600 font-semibold">Listele</router-link>
          </div>
          <p class="mt-2 text-5xl font-semibold tracking-tight text-gray-900">{{ DashboardData.numberOfUsers }}</p>
        </div>
        <div class="bg-white rounded-md shadow p-4">
          <div class="flex flex-row align-bottom justify-between">
            <h2 class="font-semibold">Öğrenci Sayısı</h2>
            <router-link to="/school-student-list" class="text-indigo-600 font-semibold">Listele</router-link>
          </div>
          <p class="mt-2 text-5xl font-semibold tracking-tight text-gray-900">{{ DashboardData.numberOfStudents }}
          </p>
        </div>
      </div>

      <div class="grid grid-flow-row gap-4">
        <div class="bg-white rounded-md shadow p-4">
          <h2 class="font-semibold">Veli Değerlendirmesi</h2>
          <div class="mt-2 text-2xl font-semibold tracking-tight text-gray-900">
            <div class="flex items-center">
              <svg class="w-6 h-6 text-yellow-300 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 22 20">
                <path
                  d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg class="w-6 h-6 text-yellow-300 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 22 20">
                <path
                  d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg class="w-6 h-6 text-yellow-300 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 22 20">
                <path
                  d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg class="w-6 h-6 text-yellow-300 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 22 20">
                <path
                  d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg class="w-6 h-6 ms-1 text-gray-300 dark:text-gray-500" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                <path
                  d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <span class="pl-4 text-orange-400">5,4</span>
            </div>
            <p class="text-md pt-4">154 değerlendirme</p>
            <div class="flex items-center mt-4">
              <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">5 star</a>
              <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                <div class="h-5 bg-yellow-300 rounded" style="width: 70%"></div>
              </div>
              <span class="text-sm font-medium text-gray-500 dark:text-gray-400">70%</span>
            </div>
            <div class="flex items-center mt-4">
              <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">4 star</a>
              <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                <div class="h-5 bg-yellow-300 rounded" style="width: 17%"></div>
              </div>
              <span class="text-sm font-medium text-gray-500 dark:text-gray-400">17%</span>
            </div>
            <div class="flex items-center mt-4">
              <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">3 star</a>
              <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                <div class="h-5 bg-yellow-300 rounded" style="width: 8%"></div>
              </div>
              <span class="text-sm font-medium text-gray-500 dark:text-gray-400">8%</span>
            </div>
            <div class="flex items-center mt-4">
              <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">2 star</a>
              <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                <div class="h-5 bg-yellow-300 rounded" style="width: 4%"></div>
              </div>
              <span class="text-sm font-medium text-gray-500 dark:text-gray-400">4%</span>
            </div>
            <div class="flex items-center mt-4">
              <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline">1 star</a>
              <div class="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                <div class="h-5 bg-yellow-300 rounded" style="width: 1%"></div>
              </div>
              <span class="text-sm font-medium text-gray-500 dark:text-gray-400">1%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getSchool } from '@/services/schoolService';
import { getDashboardData } from '@/services/dashboardService';
import { School, DashboardDto } from '@/models/models';
import { toast } from 'vue3-toastify';

export default defineComponent({
  name: 'Dashboard',
  components: {},
  setup() {
    const Loading = ref<boolean>(true);
    const School = ref<School>({
      schoolId: 0,
      name: '',
      slug: '',
      description: '',
      openAiApiKey: '',
      createdAt: new Date(),
    });
    const DashboardData = ref<DashboardDto>({
      numberOfActiveAiAssistants: 0,
      numberOfStudents: 0,
      numberOfUsers: 0,
    });
    const route = useRoute();

    onMounted(() => {
      fetchViewData()
    });

    const fetchViewData = async () => {

      const SchoolData = await getSchool();
      School.value = SchoolData.data.data;

      const DasboardDataResponse = await getDashboardData();
      DashboardData.value = DasboardDataResponse.data.data;

      Loading.value = false;
    };
    const copyToClipboard = () => {
      navigator.clipboard.writeText(School.value.slug);
      toast.info('Bağlantı kopyalandı.');
    };

    return {
      Loading,
      School,
      DashboardData,
      copyToClipboard
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
